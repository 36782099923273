/*------------------------------------------------------------------

    [Example]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

/* colors example classes */
.invert{
    .color_example{        
        background: $invert-sidepanel-background;        
        border-color: $invert-sidepanel-border;
        
        .caption{
            border-top-color: $invert-sidepanel-border;                    

            strong{
                color: $invert-color;
            }
        }
    }
}
/* end colors example classes */

/* Typography Example Classes */
.invert{
    .typography_example{    
        background: $invert-sidepanel-background;        
        border-color: $invert-sidepanel-border;

        .text{        
            color: $invert-color-heading;
            border-bottom-color: $invert-border-color;
        }    
    }
}
/* end Typography Example Classes */