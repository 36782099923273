/*------------------------------------------------------------------

    [Layout]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

.page{
    .page__header{
        &.invert{
            background: $invert-header-background;
            color: $invert-color;        
            border-bottom-color: $invert-header-border;
            @if $shadows != 0 {
                box-shadow: 0px 2px 12px rgba(0,0,0,0.2);
            }
        }        
    }    
    
    .page__container{
        &.invert{
            background: $invert-header-background;
            color: $invert-color;        
            border-bottom-color: $invert-header-border;
        }
    }
    
    .page__content{
        &.page__content-invert{
            background: $invert-content-background;
        }
        
        .page-aside{
            &.invert{
                background-color: $invert-aside-background;
                color: $invert-color;            
                border-right-color: $invert-aside-border;
                @if $shadows != 0 {
                    box-shadow: 2px 0px 12px rgba(0,0,0,0.2);
                }
                                
                .page-aside-controls{
                    background: darken($invert-aside-background,2%);
                    border-top: 1px solid $invert-aside-border;                        
                }                
            }
        }
        
        .page-sidepanel{
            &.invert{
                background: $invert-sidepanel-background;
                color: $invert-color;
                border-right-color: $invert-sidepanel-border;
                
                > .page-sidepanel__button{
                    background: $invert-sidepanel-background;
                    border: 1px solid $invert-sidepanel-border;
                    border-left-color: $invert-sidepanel-background;
                    
                    div{
                        border-color: transparent $invert-color transparent transparent;                        
                    }                                        
                }
                &.page-sidepanel--hidden{
                    > .page-sidepanel__button{
                        div{
                            border-color: transparent transparent transparent $invert-color;
                        }
                    }
                }
            }
        }
        
        .important-container{
            &.invert{
                background: $invert-sidepanel-background;
                color: $invert-color;
            }
        }
    }
}
// end basic layout invert styles