/*------------------------------------------------------------------

    [Typography]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

.invert{
    color: $invert-color;
    
    h1,h2,h3,h4,h5,h6{
        color: $invert-color-heading;
    }
    
    p{
        &.caption{
            color: $invert-color-secondary !important;
        }
    }
    small{
        color: $invert-color-secondary !important;
    }
    .text-muted{
        color: $invert-color-secondary !important;
    }
    code{
        background: darken(#22212f,5%);
        color: $invert-color;
    }
    pre{
        color: $invert-color;
    }
    
    .styled-list{
        > li{
            > a{
                color: $invert-color-heading;
            }
        }
    }
}    
// end 