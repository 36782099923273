/*------------------------------------------------------------------

    [Buttons]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/
// rw buttons
.invert{    
    
    .rw-btn{
        &--nav, &--minimized{
            span{
                background: $invert-color;
                &:after,&:before{
                    background: $invert-color;
                }
            }
        }
        
        &--card{
            border-color: $invert-border-color;
            
            > div{
                border-color: $invert-color-secondary;
                
                &:after,&:before{
                    border-color: $invert-color-secondary;    
                }
            }
        }
    }
    
    .dropdown{
        &.show{        
            .rw-btn--card{
                background: $invert-border-color;

                > div{
                    background: $invert-color;

                    &:before{
                        background: $invert-color;
                    }
                }
            }        
        }
    }
}
// end rw buttons

// dropdown support

