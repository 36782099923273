.wizard{
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .anchor{
        width: 100%;
        float: left; 
        padding: 0px; 
        margin: 0px; 
        list-style: none; 
        display: block; 
        position: relative; 

        &:after{
            content: " "; 
            position: absolute; 
            bottom: 0px; 
            left: 0px; 
            width: 100%; 
            height: 3px;
            background: $border-color; 
            z-index: 1;
            border-radius: $border-radius;
        }

        li{
            float: left; 
            display: block;

            a{
                color: $color-heading; 
                text-decoration: none; 
                display: block;
                padding: 10px 0px 30px 0px; 
                position: relative;
                font-size: 16px;                

                .stepNumber{display: none;}
                .stepDesc{
                    font-size: $font-size;
                    font-weight: 600;
                    line-height: $line-height;
                    display: block;

                    small{
                        color: $color-secondary; 
                        font-size: $font-size;                        
                        line-height: $line-height;                                                
                    }
                }

                &:before{
                    content: " "; 
                    position: absolute; 
                    bottom: 0px; 
                    left: 0px; 
                    width: 100%; 
                    height: 3px; 
                    background: $border-color; 
                    z-index: 2;                         
                }

                &:after{
                    content: " ";
                    position: absolute;
                    bottom: -15px;
                    left: 0px;
                    width: 35px;
                    height: 35px;
                    background: #FFF;
                    border: 3px solid $border-color;
                    z-index: 3;
                    text-align: center;
                    line-height: 28px;
                    color: $color-heading;
                    border-radius: $border-radius;
                }

                &.done{
                    &:after{
                        border-color: $success-background; 
                        background: $success-background;
                        color: $success-color;
                        content: "\f00c"; 
                        font-family: FontAwesome;                        
                    }
                    &:before{
                        background: linear-gradient(to right, $success-background, $secondary-background);
                    }
                    &.active{
                        &:before{
                            background: linear-gradient(to right, $success-background, $success-background);
                        }
                    }
                }
                &.error{
                    &:after{
                        border-color: $border-color; 
                        background: lighten($danger-background,5%); 
                        content: "\f00d"; 
                        font-family: FontAwesome; 
                        color: $danger-color; 
                    }
                    &:before{
                        background: $danger-background;
                    }
                }

                &.selected{
                    &:after{
                        border-color: $secondary-background;
                        background: lighten($secondary-background,5%);
                        content: "\f0da"; 
                        font-family: FontAwesome; 
                        color: $secondary-color;
                        padding-left: 2px;
                        border-width: 3px;
                        line-height: 28px;
                    }
                    &:before{                        
                        background: linear-gradient(to right, $secondary-background, $border-color);
                    }
                    &.active{
                        &:after{
                            border-color: rgba(0,0,0,0.1);
                            background: $success-background;
                            color: $success-color;                            
                            content: "\f00c";
                            padding-left: 0px;
                        }
                        &:before{
                            background: linear-gradient(to right, $success-background, $success-background);                            
                        }
                    }
                }

            }                        
            
        }

        &.steps_1 li{width: 100%;}
        &.steps_2 li{width: 50%;}
        &.steps_3 li{width: 33.333333%;}
        &.steps_4 li{width: 25%;}
        &.steps_5 li{width: 20%;}
        &.steps_6 li{width: 16.666666%;}
        &.steps_7 li{width: 14.285714%;}
        &.steps_8 li{width: 12.5%;}
        
        
        
    }

    .stepContainer, .actionBar{
        float: left; 
        width: 100%; 
        margin-top: 50px;
    }

    .actionBar{
        margin-top: 20px;

        .btn.pull-right{margin-left: 5px;}
        .btn-secondary{display: none;}
        .loader{display: none;}
    }
    
    
    &.show-submit.show-submit-box{
        > ul{
            > li:last-child{

                &:before{
                    content: " ";
                    position: absolute;
                    bottom: -15px;
                    right: 0px;
                    width: 35px;
                    height: 35px;
                    background: #FFF;
                    border: 3px solid $border-color;
                    z-index: 3;
                    text-align: center;
                    line-height: 28px;
                    color: $color-heading;
                    border-radius: $border-radius;
                }

            }
        }
        
        &.submited{
            > ul {
                > li:last-child{

                    &:before{
                        border-color: rgba(0,0,0,0.1);
                        background: $success-background;                            
                        color: $success-color;
                        content: "\f00c"; 
                        font-family: FontAwesome;
                    }

                }
            }
        }
    }
}