/*------------------------------------------------------------------

    [Custom form styles]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]    
    

    [Table of contents]

    1. Switch button

-------------------------------------------------------------------*/

/*-----
    [Switch button]
    
    Custom radio and checkbox style

    .switch                 - wrapper
        & .switch-lg        - [modify] large switch button
        & .switch-sm        - [modify] small switch button   
-----*/
.switch{
    cursor:pointer;
    position:relative; 
    height: 30px;
    line-height: 30px;
    margin: 5px 0px;

    input{
        position:absolute;
        opacity:0;
        filter:alpha(opacity=0);

        &:checked+span:after{left: 16px;}
        &:checked+span{background-color: $success-background;}
        &:disabled+span{
            opacity: 0.5;

            &:after{
                box-shadow: none;
                border: 1px solid $border-color;
            }
        }
    }            

    span{
        position:relative; 
        width: 45px;
        height: 30px;
        border-radius: 30px;
        background-color: $danger-background; 
        border: 1px solid rgba(0,0,0,0.1);
        display: inline-block; 
        @if $animation != 0 {
            @include transition(all $animation ease-in-out);
        }
        left: 0px;

        &:after{
            content:""; 
            position:absolute; 
            background-color: #fff;
            width: 26px;
            top:1px; 
            bottom:1px; 
            left:1px; 
            border-radius: 25px;
            box-shadow: 1px 1px 2px rgba(0,0,0,0.1); 
            @if $animation != 0 {
                @include transition(all $animation ease-in-out);
            }
        }
    }            
    /*-----
        [.switch-lg large switch button]
    -----*/
    &.switch-lg{
        margin: 0px; 
        height: 35px; 
        line-height: 35px;
        margin: 0px !important;

        input{
            &:checked+span:after{left: 27px;}
        }

        span{
            width: 60px; 
            height: 35px; 
            border-radius: 30px;

            &:after{width: 30px;}
        }
    }
    
    /*-----
        [.switch-lg small switch button]
    -----*/
    &.switch-sm{
        margin: 0px; 
        height: 20px; 
        line-height: 20px;
        margin: 10px 0px;

        input{
            &:checked+span:after{left: 16px;}
        }

        span{
            width: 35px; 
            height: 20px; 
            border-radius: 10px;

            &:after{width: 16px;}
        }
    }

}
/* ./switch button */