/*------------------------------------------------------------------

    [Default skin variables]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

/* Base feature colors */
$base-skin-background: #FFF;
$base-skin-color: #20232a;    

/* Content */
$content-background: #f6f6f6;    

/* Header */
$header-background: #fff;
    $header-border: $border-color;    

/* Container */
$container-background: #fff;
    $container-border: $border-color;    

/* Aside */
$aside-background: #fff;
$aside-border: $border-color;    

/* Sidepanel */
$sidepanel-background: $base-skin-background;
$sidepanel-border: $border-color;    

/* Logo options */
$logo-image: url("../assets/img/logo.png") left top no-repeat;
$logo-image-2x: "../assets/img/logo-2x.png";
// end logo settings

/* Typography */
$color: #323c47;
$color-heading: #242437;
$color-secondary: #91969a;
$color-hover: #5f8eea;
$color-link: $primary-background;
$color-link-hover: darken($primary-background,10%);
$color-invert: #FFF;

/* Navigation */
$navigation-color:  $color;
$navigation-color-hover: darken($navigation-color,5%);
$navigation-color-active: darken($navigation-color,10%);
$navigation-icon-color: $navigation-color;
$navigation-icon-color-active: $primary-background;
$navigation-background: transparent;
$navigation-background-hover: rgba(0,0,0,0.03);
$navigation-background-active: rgba(0,0,0,0.05);    

/* Form elements and components */
$form-background: #fff;
$form-background-disabled: darken($form-background,5%);
$form-border: $border-color;
$form-focus: #2c9fdd;