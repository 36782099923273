@font-face {
    font-family: 'linearicons';
    src:    url('fonts/linearicons.eot?8ny2i2');
    src:    url('fonts/linearicons.eot?8ny2i2#iefix') format('embedded-opentype'),
            url('fonts/linearicons.ttf?8ny2i2') format('truetype'),
            url('fonts/linearicons.woff?8ny2i2') format('woff'),
            url('fonts/linearicons.svg?8ny2i2#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="li-"], [class*=" li-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'linearicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.li-home:before {
    content: "\e600";
}
.li-home2:before {
    content: "\e601";
}
.li-home3:before {
    content: "\e602";
}
.li-home4:before {
    content: "\e603";
}
.li-home5:before {
    content: "\e604";
}
.li-home6:before {
    content: "\e605";
}
.li-bathtub:before {
    content: "\e606";
}
.li-toothbrush:before {
    content: "\e607";
}
.li-bed:before {
    content: "\e608";
}
.li-couch:before {
    content: "\e609";
}
.li-chair:before {
    content: "\e60a";
}
.li-city:before {
    content: "\e60b";
}
.li-apartment:before {
    content: "\e60c";
}
.li-pencil:before {
    content: "\e60d";
}
.li-pencil2:before {
    content: "\e60e";
}
.li-pen:before {
    content: "\e60f";
}
.li-pencil3:before {
    content: "\e610";
}
.li-eraser:before {
    content: "\e611";
}
.li-pencil4:before {
    content: "\e612";
}
.li-pencil5:before {
    content: "\e613";
}
.li-feather:before {
    content: "\e614";
}
.li-feather2:before {
    content: "\e615";
}
.li-feather3:before {
    content: "\e616";
}
.li-pen2:before {
    content: "\e617";
}
.li-pen-add:before {
    content: "\e618";
}
.li-pen-remove:before {
    content: "\e619";
}
.li-vector:before {
    content: "\e61a";
}
.li-pen3:before {
    content: "\e61b";
}
.li-blog:before {
    content: "\e61c";
}
.li-brush:before {
    content: "\e61d";
}
.li-brush2:before {
    content: "\e61e";
}
.li-spray:before {
    content: "\e61f";
}
.li-paint-roller:before {
    content: "\e620";
}
.li-stamp:before {
    content: "\e621";
}
.li-tape:before {
    content: "\e622";
}
.li-desk-tape:before {
    content: "\e623";
}
.li-texture:before {
    content: "\e624";
}
.li-eye-dropper:before {
    content: "\e625";
}
.li-palette:before {
    content: "\e626";
}
.li-color-sampler:before {
    content: "\e627";
}
.li-bucket:before {
    content: "\e628";
}
.li-gradient:before {
    content: "\e629";
}
.li-gradient2:before {
    content: "\e62a";
}
.li-magic-wand:before {
    content: "\e62b";
}
.li-magnet:before {
    content: "\e62c";
}
.li-pencil-ruler:before {
    content: "\e62d";
}
.li-pencil-ruler2:before {
    content: "\e62e";
}
.li-compass:before {
    content: "\e62f";
}
.li-aim:before {
    content: "\e630";
}
.li-gun:before {
    content: "\e631";
}
.li-bottle:before {
    content: "\e632";
}
.li-drop:before {
    content: "\e633";
}
.li-drop-crossed:before {
    content: "\e634";
}
.li-drop2:before {
    content: "\e635";
}
.li-snow:before {
    content: "\e636";
}
.li-snow2:before {
    content: "\e637";
}
.li-fire:before {
    content: "\e638";
}
.li-lighter:before {
    content: "\e639";
}
.li-knife:before {
    content: "\e63a";
}
.li-dagger:before {
    content: "\e63b";
}
.li-tissue:before {
    content: "\e63c";
}
.li-toilet-paper:before {
    content: "\e63d";
}
.li-poop:before {
    content: "\e63e";
}
.li-umbrella:before {
    content: "\e63f";
}
.li-umbrella2:before {
    content: "\e640";
}
.li-rain:before {
    content: "\e641";
}
.li-tornado:before {
    content: "\e642";
}
.li-wind:before {
    content: "\e643";
}
.li-fan:before {
    content: "\e644";
}
.li-contrast:before {
    content: "\e645";
}
.li-sun-small:before {
    content: "\e646";
}
.li-sun:before {
    content: "\e647";
}
.li-sun2:before {
    content: "\e648";
}
.li-moon:before {
    content: "\e649";
}
.li-cloud:before {
    content: "\e64a";
}
.li-cloud-upload:before {
    content: "\e64b";
}
.li-cloud-download:before {
    content: "\e64c";
}
.li-cloud-rain:before {
    content: "\e64d";
}
.li-cloud-hailstones:before {
    content: "\e64e";
}
.li-cloud-snow:before {
    content: "\e64f";
}
.li-cloud-windy:before {
    content: "\e650";
}
.li-sun-wind:before {
    content: "\e651";
}
.li-cloud-fog:before {
    content: "\e652";
}
.li-cloud-sun:before {
    content: "\e653";
}
.li-cloud-lightning:before {
    content: "\e654";
}
.li-cloud-sync:before {
    content: "\e655";
}
.li-cloud-lock:before {
    content: "\e656";
}
.li-cloud-gear:before {
    content: "\e657";
}
.li-cloud-alert:before {
    content: "\e658";
}
.li-cloud-check:before {
    content: "\e659";
}
.li-cloud-cross:before {
    content: "\e65a";
}
.li-cloud-crossed:before {
    content: "\e65b";
}
.li-cloud-database:before {
    content: "\e65c";
}
.li-database:before {
    content: "\e65d";
}
.li-database-add:before {
    content: "\e65e";
}
.li-database-remove:before {
    content: "\e65f";
}
.li-database-lock:before {
    content: "\e660";
}
.li-database-refresh:before {
    content: "\e661";
}
.li-database-check:before {
    content: "\e662";
}
.li-database-history:before {
    content: "\e663";
}
.li-database-upload:before {
    content: "\e664";
}
.li-database-download:before {
    content: "\e665";
}
.li-server:before {
    content: "\e666";
}
.li-shield:before {
    content: "\e667";
}
.li-shield-check:before {
    content: "\e668";
}
.li-shield-alert:before {
    content: "\e669";
}
.li-shield-cross:before {
    content: "\e66a";
}
.li-lock:before {
    content: "\e66b";
}
.li-rotation-lock:before {
    content: "\e66c";
}
.li-unlock:before {
    content: "\e66d";
}
.li-key:before {
    content: "\e66e";
}
.li-key-hole:before {
    content: "\e66f";
}
.li-toggle-off:before {
    content: "\e670";
}
.li-toggle-on:before {
    content: "\e671";
}
.li-cog:before {
    content: "\e672";
}
.li-cog2:before {
    content: "\e673";
}
.li-wrench:before {
    content: "\e674";
}
.li-screwdriver:before {
    content: "\e675";
}
.li-hammer-wrench:before {
    content: "\e676";
}
.li-hammer:before {
    content: "\e677";
}
.li-saw:before {
    content: "\e678";
}
.li-axe:before {
    content: "\e679";
}
.li-axe2:before {
    content: "\e67a";
}
.li-shovel:before {
    content: "\e67b";
}
.li-pickaxe:before {
    content: "\e67c";
}
.li-factory:before {
    content: "\e67d";
}
.li-factory2:before {
    content: "\e67e";
}
.li-recycle:before {
    content: "\e67f";
}
.li-trash:before {
    content: "\e680";
}
.li-trash2:before {
    content: "\e681";
}
.li-trash3:before {
    content: "\e682";
}
.li-broom:before {
    content: "\e683";
}
.li-game:before {
    content: "\e684";
}
.li-gamepad:before {
    content: "\e685";
}
.li-joystick:before {
    content: "\e686";
}
.li-dice:before {
    content: "\e687";
}
.li-spades:before {
    content: "\e688";
}
.li-diamonds:before {
    content: "\e689";
}
.li-clubs:before {
    content: "\e68a";
}
.li-hearts:before {
    content: "\e68b";
}
.li-heart:before {
    content: "\e68c";
}
.li-star:before {
    content: "\e68d";
}
.li-star-half:before {
    content: "\e68e";
}
.li-star-empty:before {
    content: "\e68f";
}
.li-flag:before {
    content: "\e690";
}
.li-flag2:before {
    content: "\e691";
}
.li-flag3:before {
    content: "\e692";
}
.li-mailbox-full:before {
    content: "\e693";
}
.li-mailbox-empty:before {
    content: "\e694";
}
.li-at-sign:before {
    content: "\e695";
}
.li-envelope:before {
    content: "\e696";
}
.li-envelope-open:before {
    content: "\e697";
}
.li-paperclip:before {
    content: "\e698";
}
.li-paper-plane:before {
    content: "\e699";
}
.li-reply:before {
    content: "\e69a";
}
.li-reply-all:before {
    content: "\e69b";
}
.li-inbox:before {
    content: "\e69c";
}
.li-inbox2:before {
    content: "\e69d";
}
.li-outbox:before {
    content: "\e69e";
}
.li-box:before {
    content: "\e69f";
}
.li-archive:before {
    content: "\e6a0";
}
.li-archive2:before {
    content: "\e6a1";
}
.li-drawers:before {
    content: "\e6a2";
}
.li-drawers2:before {
    content: "\e6a3";
}
.li-drawers3:before {
    content: "\e6a4";
}
.li-eye:before {
    content: "\e6a5";
}
.li-eye-crossed:before {
    content: "\e6a6";
}
.li-eye-plus:before {
    content: "\e6a7";
}
.li-eye-minus:before {
    content: "\e6a8";
}
.li-binoculars:before {
    content: "\e6a9";
}
.li-binoculars2:before {
    content: "\e6aa";
}
.li-hdd:before {
    content: "\e6ab";
}
.li-hdd-down:before {
    content: "\e6ac";
}
.li-hdd-up:before {
    content: "\e6ad";
}
.li-floppy-disk:before {
    content: "\e6ae";
}
.li-disc:before {
    content: "\e6af";
}
.li-tape2:before {
    content: "\e6b0";
}
.li-printer:before {
    content: "\e6b1";
}
.li-shredder:before {
    content: "\e6b2";
}
.li-file-empty:before {
    content: "\e6b3";
}
.li-file-add:before {
    content: "\e6b4";
}
.li-file-check:before {
    content: "\e6b5";
}
.li-file-lock:before {
    content: "\e6b6";
}
.li-files:before {
    content: "\e6b7";
}
.li-copy:before {
    content: "\e6b8";
}
.li-compare:before {
    content: "\e6b9";
}
.li-folder:before {
    content: "\e6ba";
}
.li-folder-search:before {
    content: "\e6bb";
}
.li-folder-plus:before {
    content: "\e6bc";
}
.li-folder-minus:before {
    content: "\e6bd";
}
.li-folder-download:before {
    content: "\e6be";
}
.li-folder-upload:before {
    content: "\e6bf";
}
.li-folder-star:before {
    content: "\e6c0";
}
.li-folder-heart:before {
    content: "\e6c1";
}
.li-folder-user:before {
    content: "\e6c2";
}
.li-folder-shared:before {
    content: "\e6c3";
}
.li-folder-music:before {
    content: "\e6c4";
}
.li-folder-picture:before {
    content: "\e6c5";
}
.li-folder-film:before {
    content: "\e6c6";
}
.li-scissors:before {
    content: "\e6c7";
}
.li-paste:before {
    content: "\e6c8";
}
.li-clipboard-empty:before {
    content: "\e6c9";
}
.li-clipboard-pencil:before {
    content: "\e6ca";
}
.li-clipboard-text:before {
    content: "\e6cb";
}
.li-clipboard-check:before {
    content: "\e6cc";
}
.li-clipboard-down:before {
    content: "\e6cd";
}
.li-clipboard-left:before {
    content: "\e6ce";
}
.li-clipboard-alert:before {
    content: "\e6cf";
}
.li-clipboard-user:before {
    content: "\e6d0";
}
.li-register:before {
    content: "\e6d1";
}
.li-enter:before {
    content: "\e6d2";
}
.li-exit:before {
    content: "\e6d3";
}
.li-papers:before {
    content: "\e6d4";
}
.li-news:before {
    content: "\e6d5";
}
.li-reading:before {
    content: "\e6d6";
}
.li-typewriter:before {
    content: "\e6d7";
}
.li-document:before {
    content: "\e6d8";
}
.li-document2:before {
    content: "\e6d9";
}
.li-graduation-hat:before {
    content: "\e6da";
}
.li-license:before {
    content: "\e6db";
}
.li-license2:before {
    content: "\e6dc";
}
.li-medal-empty:before {
    content: "\e6dd";
}
.li-medal-first:before {
    content: "\e6de";
}
.li-medal-second:before {
    content: "\e6df";
}
.li-medal-third:before {
    content: "\e6e0";
}
.li-podium:before {
    content: "\e6e1";
}
.li-trophy:before {
    content: "\e6e2";
}
.li-trophy2:before {
    content: "\e6e3";
}
.li-music-note:before {
    content: "\e6e4";
}
.li-music-note2:before {
    content: "\e6e5";
}
.li-music-note3:before {
    content: "\e6e6";
}
.li-playlist:before {
    content: "\e6e7";
}
.li-playlist-add:before {
    content: "\e6e8";
}
.li-guitar:before {
    content: "\e6e9";
}
.li-trumpet:before {
    content: "\e6ea";
}
.li-album:before {
    content: "\e6eb";
}
.li-shuffle:before {
    content: "\e6ec";
}
.li-repeat-one:before {
    content: "\e6ed";
}
.li-repeat:before {
    content: "\e6ee";
}
.li-headphones:before {
    content: "\e6ef";
}
.li-headset:before {
    content: "\e6f0";
}
.li-loudspeaker:before {
    content: "\e6f1";
}
.li-equalizer:before {
    content: "\e6f2";
}
.li-theater:before {
    content: "\e6f3";
}
.li-3d-glasses:before {
    content: "\e6f4";
}
.li-ticket:before {
    content: "\e6f5";
}
.li-presentation:before {
    content: "\e6f6";
}
.li-play:before {
    content: "\e6f7";
}
.li-film-play:before {
    content: "\e6f8";
}
.li-clapboard-play:before {
    content: "\e6f9";
}
.li-media:before {
    content: "\e6fa";
}
.li-film:before {
    content: "\e6fb";
}
.li-film2:before {
    content: "\e6fc";
}
.li-surveillance:before {
    content: "\e6fd";
}
.li-surveillance2:before {
    content: "\e6fe";
}
.li-camera:before {
    content: "\e6ff";
}
.li-camera-crossed:before {
    content: "\e700";
}
.li-camera-play:before {
    content: "\e701";
}
.li-time-lapse:before {
    content: "\e702";
}
.li-record:before {
    content: "\e703";
}
.li-camera2:before {
    content: "\e704";
}
.li-camera-flip:before {
    content: "\e705";
}
.li-panorama:before {
    content: "\e706";
}
.li-time-lapse2:before {
    content: "\e707";
}
.li-shutter:before {
    content: "\e708";
}
.li-shutter2:before {
    content: "\e709";
}
.li-face-detection:before {
    content: "\e70a";
}
.li-flare:before {
    content: "\e70b";
}
.li-convex:before {
    content: "\e70c";
}
.li-concave:before {
    content: "\e70d";
}
.li-picture:before {
    content: "\e70e";
}
.li-picture2:before {
    content: "\e70f";
}
.li-picture3:before {
    content: "\e710";
}
.li-pictures:before {
    content: "\e711";
}
.li-book:before {
    content: "\e712";
}
.li-audio-book:before {
    content: "\e713";
}
.li-book2:before {
    content: "\e714";
}
.li-bookmark:before {
    content: "\e715";
}
.li-bookmark2:before {
    content: "\e716";
}
.li-label:before {
    content: "\e717";
}
.li-library:before {
    content: "\e718";
}
.li-library2:before {
    content: "\e719";
}
.li-contacts:before {
    content: "\e71a";
}
.li-profile:before {
    content: "\e71b";
}
.li-portrait:before {
    content: "\e71c";
}
.li-portrait2:before {
    content: "\e71d";
}
.li-user:before {
    content: "\e71e";
}
.li-user-plus:before {
    content: "\e71f";
}
.li-user-minus:before {
    content: "\e720";
}
.li-user-lock:before {
    content: "\e721";
}
.li-users:before {
    content: "\e722";
}
.li-users2:before {
    content: "\e723";
}
.li-users-plus:before {
    content: "\e724";
}
.li-users-minus:before {
    content: "\e725";
}
.li-group-work:before {
    content: "\e726";
}
.li-woman:before {
    content: "\e727";
}
.li-man:before {
    content: "\e728";
}
.li-baby:before {
    content: "\e729";
}
.li-baby2:before {
    content: "\e72a";
}
.li-baby3:before {
    content: "\e72b";
}
.li-baby-bottle:before {
    content: "\e72c";
}
.li-walk:before {
    content: "\e72d";
}
.li-hand-waving:before {
    content: "\e72e";
}
.li-jump:before {
    content: "\e72f";
}
.li-run:before {
    content: "\e730";
}
.li-woman2:before {
    content: "\e731";
}
.li-man2:before {
    content: "\e732";
}
.li-man-woman:before {
    content: "\e733";
}
.li-height:before {
    content: "\e734";
}
.li-weight:before {
    content: "\e735";
}
.li-scale:before {
    content: "\e736";
}
.li-button:before {
    content: "\e737";
}
.li-bow-tie:before {
    content: "\e738";
}
.li-tie:before {
    content: "\e739";
}
.li-socks:before {
    content: "\e73a";
}
.li-shoe:before {
    content: "\e73b";
}
.li-shoes:before {
    content: "\e73c";
}
.li-hat:before {
    content: "\e73d";
}
.li-pants:before {
    content: "\e73e";
}
.li-shorts:before {
    content: "\e73f";
}
.li-flip-flops:before {
    content: "\e740";
}
.li-shirt:before {
    content: "\e741";
}
.li-hanger:before {
    content: "\e742";
}
.li-laundry:before {
    content: "\e743";
}
.li-store:before {
    content: "\e744";
}
.li-haircut:before {
    content: "\e745";
}
.li-store-24:before {
    content: "\e746";
}
.li-barcode:before {
    content: "\e747";
}
.li-barcode2:before {
    content: "\e748";
}
.li-barcode3:before {
    content: "\e749";
}
.li-cashier:before {
    content: "\e74a";
}
.li-bag:before {
    content: "\e74b";
}
.li-bag2:before {
    content: "\e74c";
}
.li-cart:before {
    content: "\e74d";
}
.li-cart-empty:before {
    content: "\e74e";
}
.li-cart-full:before {
    content: "\e74f";
}
.li-cart-plus:before {
    content: "\e750";
}
.li-cart-plus2:before {
    content: "\e751";
}
.li-cart-add:before {
    content: "\e752";
}
.li-cart-remove:before {
    content: "\e753";
}
.li-cart-exchange:before {
    content: "\e754";
}
.li-tag:before {
    content: "\e755";
}
.li-tags:before {
    content: "\e756";
}
.li-receipt:before {
    content: "\e757";
}
.li-wallet:before {
    content: "\e758";
}
.li-credit-card:before {
    content: "\e759";
}
.li-cash-dollar:before {
    content: "\e75a";
}
.li-cash-euro:before {
    content: "\e75b";
}
.li-cash-pound:before {
    content: "\e75c";
}
.li-cash-yen:before {
    content: "\e75d";
}
.li-bag-dollar:before {
    content: "\e75e";
}
.li-bag-euro:before {
    content: "\e75f";
}
.li-bag-pound:before {
    content: "\e760";
}
.li-bag-yen:before {
    content: "\e761";
}
.li-coin-dollar:before {
    content: "\e762";
}
.li-coin-euro:before {
    content: "\e763";
}
.li-coin-pound:before {
    content: "\e764";
}
.li-coin-yen:before {
    content: "\e765";
}
.li-calculator:before {
    content: "\e766";
}
.li-calculator2:before {
    content: "\e767";
}
.li-abacus:before {
    content: "\e768";
}
.li-vault:before {
    content: "\e769";
}
.li-telephone:before {
    content: "\e76a";
}
.li-phone-lock:before {
    content: "\e76b";
}
.li-phone-wave:before {
    content: "\e76c";
}
.li-phone-pause:before {
    content: "\e76d";
}
.li-phone-outgoing:before {
    content: "\e76e";
}
.li-phone-incoming:before {
    content: "\e76f";
}
.li-phone-in-out:before {
    content: "\e770";
}
.li-phone-error:before {
    content: "\e771";
}
.li-phone-sip:before {
    content: "\e772";
}
.li-phone-plus:before {
    content: "\e773";
}
.li-phone-minus:before {
    content: "\e774";
}
.li-voicemail:before {
    content: "\e775";
}
.li-dial:before {
    content: "\e776";
}
.li-telephone2:before {
    content: "\e777";
}
.li-pushpin:before {
    content: "\e778";
}
.li-pushpin2:before {
    content: "\e779";
}
.li-map-marker:before {
    content: "\e77a";
}
.li-map-marker-user:before {
    content: "\e77b";
}
.li-map-marker-down:before {
    content: "\e77c";
}
.li-map-marker-check:before {
    content: "\e77d";
}
.li-map-marker-crossed:before {
    content: "\e77e";
}
.li-radar:before {
    content: "\e77f";
}
.li-compass2:before {
    content: "\e780";
}
.li-map:before {
    content: "\e781";
}
.li-map2:before {
    content: "\e782";
}
.li-location:before {
    content: "\e783";
}
.li-road-sign:before {
    content: "\e784";
}
.li-calendar-empty:before {
    content: "\e785";
}
.li-calendar-check:before {
    content: "\e786";
}
.li-calendar-cross:before {
    content: "\e787";
}
.li-calendar-31:before {
    content: "\e788";
}
.li-calendar-full:before {
    content: "\e789";
}
.li-calendar-insert:before {
    content: "\e78a";
}
.li-calendar-text:before {
    content: "\e78b";
}
.li-calendar-user:before {
    content: "\e78c";
}
.li-mouse:before {
    content: "\e78d";
}
.li-mouse-left:before {
    content: "\e78e";
}
.li-mouse-right:before {
    content: "\e78f";
}
.li-mouse-both:before {
    content: "\e790";
}
.li-keyboard:before {
    content: "\e791";
}
.li-keyboard-up:before {
    content: "\e792";
}
.li-keyboard-down:before {
    content: "\e793";
}
.li-delete:before {
    content: "\e794";
}
.li-spell-check:before {
    content: "\e795";
}
.li-escape:before {
    content: "\e796";
}
.li-enter2:before {
    content: "\e797";
}
.li-screen:before {
    content: "\e798";
}
.li-aspect-ratio:before {
    content: "\e799";
}
.li-signal:before {
    content: "\e79a";
}
.li-signal-lock:before {
    content: "\e79b";
}
.li-signal-80:before {
    content: "\e79c";
}
.li-signal-60:before {
    content: "\e79d";
}
.li-signal-40:before {
    content: "\e79e";
}
.li-signal-20:before {
    content: "\e79f";
}
.li-signal-0:before {
    content: "\e7a0";
}
.li-signal-blocked:before {
    content: "\e7a1";
}
.li-sim:before {
    content: "\e7a2";
}
.li-flash-memory:before {
    content: "\e7a3";
}
.li-usb-drive:before {
    content: "\e7a4";
}
.li-phone:before {
    content: "\e7a5";
}
.li-smartphone:before {
    content: "\e7a6";
}
.li-smartphone-notification:before {
    content: "\e7a7";
}
.li-smartphone-vibration:before {
    content: "\e7a8";
}
.li-smartphone-embed:before {
    content: "\e7a9";
}
.li-smartphone-waves:before {
    content: "\e7aa";
}
.li-tablet:before {
    content: "\e7ab";
}
.li-tablet2:before {
    content: "\e7ac";
}
.li-laptop:before {
    content: "\e7ad";
}
.li-laptop-phone:before {
    content: "\e7ae";
}
.li-desktop:before {
    content: "\e7af";
}
.li-launch:before {
    content: "\e7b0";
}
.li-new-tab:before {
    content: "\e7b1";
}
.li-window:before {
    content: "\e7b2";
}
.li-cable:before {
    content: "\e7b3";
}
.li-cable2:before {
    content: "\e7b4";
}
.li-tv:before {
    content: "\e7b5";
}
.li-radio:before {
    content: "\e7b6";
}
.li-remote-control:before {
    content: "\e7b7";
}
.li-power-switch:before {
    content: "\e7b8";
}
.li-power:before {
    content: "\e7b9";
}
.li-power-crossed:before {
    content: "\e7ba";
}
.li-flash-auto:before {
    content: "\e7bb";
}
.li-lamp:before {
    content: "\e7bc";
}
.li-flashlight:before {
    content: "\e7bd";
}
.li-lampshade:before {
    content: "\e7be";
}
.li-cord:before {
    content: "\e7bf";
}
.li-outlet:before {
    content: "\e7c0";
}
.li-battery-power:before {
    content: "\e7c1";
}
.li-battery-empty:before {
    content: "\e7c2";
}
.li-battery-alert:before {
    content: "\e7c3";
}
.li-battery-error:before {
    content: "\e7c4";
}
.li-battery-low1:before {
    content: "\e7c5";
}
.li-battery-low2:before {
    content: "\e7c6";
}
.li-battery-low3:before {
    content: "\e7c7";
}
.li-battery-mid1:before {
    content: "\e7c8";
}
.li-battery-mid2:before {
    content: "\e7c9";
}
.li-battery-mid3:before {
    content: "\e7ca";
}
.li-battery-full:before {
    content: "\e7cb";
}
.li-battery-charging:before {
    content: "\e7cc";
}
.li-battery-charging2:before {
    content: "\e7cd";
}
.li-battery-charging3:before {
    content: "\e7ce";
}
.li-battery-charging4:before {
    content: "\e7cf";
}
.li-battery-charging5:before {
    content: "\e7d0";
}
.li-battery-charging6:before {
    content: "\e7d1";
}
.li-battery-charging7:before {
    content: "\e7d2";
}
.li-chip:before {
    content: "\e7d3";
}
.li-chip-x64:before {
    content: "\e7d4";
}
.li-chip-x86:before {
    content: "\e7d5";
}
.li-bubble:before {
    content: "\e7d6";
}
.li-bubbles:before {
    content: "\e7d7";
}
.li-bubble-dots:before {
    content: "\e7d8";
}
.li-bubble-alert:before {
    content: "\e7d9";
}
.li-bubble-question:before {
    content: "\e7da";
}
.li-bubble-text:before {
    content: "\e7db";
}
.li-bubble-pencil:before {
    content: "\e7dc";
}
.li-bubble-picture:before {
    content: "\e7dd";
}
.li-bubble-video:before {
    content: "\e7de";
}
.li-bubble-user:before {
    content: "\e7df";
}
.li-bubble-quote:before {
    content: "\e7e0";
}
.li-bubble-heart:before {
    content: "\e7e1";
}
.li-bubble-emoticon:before {
    content: "\e7e2";
}
.li-bubble-attachment:before {
    content: "\e7e3";
}
.li-phone-bubble:before {
    content: "\e7e4";
}
.li-quote-open:before {
    content: "\e7e5";
}
.li-quote-close:before {
    content: "\e7e6";
}
.li-dna:before {
    content: "\e7e7";
}
.li-heart-pulse:before {
    content: "\e7e8";
}
.li-pulse:before {
    content: "\e7e9";
}
.li-syringe:before {
    content: "\e7ea";
}
.li-pills:before {
    content: "\e7eb";
}
.li-first-aid:before {
    content: "\e7ec";
}
.li-lifebuoy:before {
    content: "\e7ed";
}
.li-bandage:before {
    content: "\e7ee";
}
.li-bandages:before {
    content: "\e7ef";
}
.li-thermometer:before {
    content: "\e7f0";
}
.li-microscope:before {
    content: "\e7f1";
}
.li-brain:before {
    content: "\e7f2";
}
.li-beaker:before {
    content: "\e7f3";
}
.li-skull:before {
    content: "\e7f4";
}
.li-bone:before {
    content: "\e7f5";
}
.li-construction:before {
    content: "\e7f6";
}
.li-construction-cone:before {
    content: "\e7f7";
}
.li-pie-chart:before {
    content: "\e7f8";
}
.li-pie-chart2:before {
    content: "\e7f9";
}
.li-graph:before {
    content: "\e7fa";
}
.li-chart-growth:before {
    content: "\e7fb";
}
.li-chart-bars:before {
    content: "\e7fc";
}
.li-chart-settings:before {
    content: "\e7fd";
}
.li-cake:before {
    content: "\e7fe";
}
.li-gift:before {
    content: "\e7ff";
}
.li-balloon:before {
    content: "\e800";
}
.li-rank:before {
    content: "\e801";
}
.li-rank2:before {
    content: "\e802";
}
.li-rank3:before {
    content: "\e803";
}
.li-crown:before {
    content: "\e804";
}
.li-lotus:before {
    content: "\e805";
}
.li-diamond:before {
    content: "\e806";
}
.li-diamond2:before {
    content: "\e807";
}
.li-diamond3:before {
    content: "\e808";
}
.li-diamond4:before {
    content: "\e809";
}
.li-linearicons:before {
    content: "\e80a";
}
.li-teacup:before {
    content: "\e80b";
}
.li-teapot:before {
    content: "\e80c";
}
.li-glass:before {
    content: "\e80d";
}
.li-bottle2:before {
    content: "\e80e";
}
.li-glass-cocktail:before {
    content: "\e80f";
}
.li-glass2:before {
    content: "\e810";
}
.li-dinner:before {
    content: "\e811";
}
.li-dinner2:before {
    content: "\e812";
}
.li-chef:before {
    content: "\e813";
}
.li-scale2:before {
    content: "\e814";
}
.li-egg:before {
    content: "\e815";
}
.li-egg2:before {
    content: "\e816";
}
.li-eggs:before {
    content: "\e817";
}
.li-platter:before {
    content: "\e818";
}
.li-steak:before {
    content: "\e819";
}
.li-hamburger:before {
    content: "\e81a";
}
.li-hotdog:before {
    content: "\e81b";
}
.li-pizza:before {
    content: "\e81c";
}
.li-sausage:before {
    content: "\e81d";
}
.li-chicken:before {
    content: "\e81e";
}
.li-fish:before {
    content: "\e81f";
}
.li-carrot:before {
    content: "\e820";
}
.li-cheese:before {
    content: "\e821";
}
.li-bread:before {
    content: "\e822";
}
.li-ice-cream:before {
    content: "\e823";
}
.li-ice-cream2:before {
    content: "\e824";
}
.li-candy:before {
    content: "\e825";
}
.li-lollipop:before {
    content: "\e826";
}
.li-coffee-bean:before {
    content: "\e827";
}
.li-coffee-cup:before {
    content: "\e828";
}
.li-cherry:before {
    content: "\e829";
}
.li-grapes:before {
    content: "\e82a";
}
.li-citrus:before {
    content: "\e82b";
}
.li-apple:before {
    content: "\e82c";
}
.li-leaf:before {
    content: "\e82d";
}
.li-landscape:before {
    content: "\e82e";
}
.li-pine-tree:before {
    content: "\e82f";
}
.li-tree:before {
    content: "\e830";
}
.li-cactus:before {
    content: "\e831";
}
.li-paw:before {
    content: "\e832";
}
.li-footprint:before {
    content: "\e833";
}
.li-speed-slow:before {
    content: "\e834";
}
.li-speed-medium:before {
    content: "\e835";
}
.li-speed-fast:before {
    content: "\e836";
}
.li-rocket:before {
    content: "\e837";
}
.li-hammer2:before {
    content: "\e838";
}
.li-balance:before {
    content: "\e839";
}
.li-briefcase:before {
    content: "\e83a";
}
.li-luggage-weight:before {
    content: "\e83b";
}
.li-dolly:before {
    content: "\e83c";
}
.li-plane:before {
    content: "\e83d";
}
.li-plane-crossed:before {
    content: "\e83e";
}
.li-helicopter:before {
    content: "\e83f";
}
.li-traffic-lights:before {
    content: "\e840";
}
.li-siren:before {
    content: "\e841";
}
.li-road:before {
    content: "\e842";
}
.li-engine:before {
    content: "\e843";
}
.li-oil-pressure:before {
    content: "\e844";
}
.li-coolant-temperature:before {
    content: "\e845";
}
.li-car-battery:before {
    content: "\e846";
}
.li-gas:before {
    content: "\e847";
}
.li-gallon:before {
    content: "\e848";
}
.li-transmission:before {
    content: "\e849";
}
.li-car:before {
    content: "\e84a";
}
.li-car-wash:before {
    content: "\e84b";
}
.li-car-wash2:before {
    content: "\e84c";
}
.li-bus:before {
    content: "\e84d";
}
.li-bus2:before {
    content: "\e84e";
}
.li-car2:before {
    content: "\e84f";
}
.li-parking:before {
    content: "\e850";
}
.li-car-lock:before {
    content: "\e851";
}
.li-taxi:before {
    content: "\e852";
}
.li-car-siren:before {
    content: "\e853";
}
.li-car-wash3:before {
    content: "\e854";
}
.li-car-wash4:before {
    content: "\e855";
}
.li-ambulance:before {
    content: "\e856";
}
.li-truck:before {
    content: "\e857";
}
.li-trailer:before {
    content: "\e858";
}
.li-scale-truck:before {
    content: "\e859";
}
.li-train:before {
    content: "\e85a";
}
.li-ship:before {
    content: "\e85b";
}
.li-ship2:before {
    content: "\e85c";
}
.li-anchor:before {
    content: "\e85d";
}
.li-boat:before {
    content: "\e85e";
}
.li-bicycle:before {
    content: "\e85f";
}
.li-bicycle2:before {
    content: "\e860";
}
.li-dumbbell:before {
    content: "\e861";
}
.li-bench-press:before {
    content: "\e862";
}
.li-swim:before {
    content: "\e863";
}
.li-football:before {
    content: "\e864";
}
.li-baseball-bat:before {
    content: "\e865";
}
.li-baseball:before {
    content: "\e866";
}
.li-tennis:before {
    content: "\e867";
}
.li-tennis2:before {
    content: "\e868";
}
.li-ping-pong:before {
    content: "\e869";
}
.li-hockey:before {
    content: "\e86a";
}
.li-8ball:before {
    content: "\e86b";
}
.li-bowling:before {
    content: "\e86c";
}
.li-bowling-pins:before {
    content: "\e86d";
}
.li-golf:before {
    content: "\e86e";
}
.li-golf2:before {
    content: "\e86f";
}
.li-archery:before {
    content: "\e870";
}
.li-slingshot:before {
    content: "\e871";
}
.li-soccer:before {
    content: "\e872";
}
.li-basketball:before {
    content: "\e873";
}
.li-cube:before {
    content: "\e874";
}
.li-3d-rotate:before {
    content: "\e875";
}
.li-puzzle:before {
    content: "\e876";
}
.li-glasses:before {
    content: "\e877";
}
.li-glasses2:before {
    content: "\e878";
}
.li-accessibility:before {
    content: "\e879";
}
.li-wheelchair:before {
    content: "\e87a";
}
.li-wall:before {
    content: "\e87b";
}
.li-fence:before {
    content: "\e87c";
}
.li-wall2:before {
    content: "\e87d";
}
.li-icons:before {
    content: "\e87e";
}
.li-resize-handle:before {
    content: "\e87f";
}
.li-icons2:before {
    content: "\e880";
}
.li-select:before {
    content: "\e881";
}
.li-select2:before {
    content: "\e882";
}
.li-site-map:before {
    content: "\e883";
}
.li-earth:before {
    content: "\e884";
}
.li-earth-lock:before {
    content: "\e885";
}
.li-network:before {
    content: "\e886";
}
.li-network-lock:before {
    content: "\e887";
}
.li-planet:before {
    content: "\e888";
}
.li-happy:before {
    content: "\e889";
}
.li-smile:before {
    content: "\e88a";
}
.li-grin:before {
    content: "\e88b";
}
.li-tongue:before {
    content: "\e88c";
}
.li-sad:before {
    content: "\e88d";
}
.li-wink:before {
    content: "\e88e";
}
.li-dream:before {
    content: "\e88f";
}
.li-shocked:before {
    content: "\e890";
}
.li-shocked2:before {
    content: "\e891";
}
.li-tongue2:before {
    content: "\e892";
}
.li-neutral:before {
    content: "\e893";
}
.li-happy-grin:before {
    content: "\e894";
}
.li-cool:before {
    content: "\e895";
}
.li-mad:before {
    content: "\e896";
}
.li-grin-evil:before {
    content: "\e897";
}
.li-evil:before {
    content: "\e898";
}
.li-wow:before {
    content: "\e899";
}
.li-annoyed:before {
    content: "\e89a";
}
.li-wondering:before {
    content: "\e89b";
}
.li-confused:before {
    content: "\e89c";
}
.li-zipped:before {
    content: "\e89d";
}
.li-grumpy:before {
    content: "\e89e";
}
.li-mustache:before {
    content: "\e89f";
}
.li-tombstone-hipster:before {
    content: "\e8a0";
}
.li-tombstone:before {
    content: "\e8a1";
}
.li-ghost:before {
    content: "\e8a2";
}
.li-ghost-hipster:before {
    content: "\e8a3";
}
.li-halloween:before {
    content: "\e8a4";
}
.li-christmas:before {
    content: "\e8a5";
}
.li-easter-egg:before {
    content: "\e8a6";
}
.li-mustache2:before {
    content: "\e8a7";
}
.li-mustache-glasses:before {
    content: "\e8a8";
}
.li-pipe:before {
    content: "\e8a9";
}
.li-alarm:before {
    content: "\e8aa";
}
.li-alarm-add:before {
    content: "\e8ab";
}
.li-alarm-snooze:before {
    content: "\e8ac";
}
.li-alarm-ringing:before {
    content: "\e8ad";
}
.li-bullhorn:before {
    content: "\e8ae";
}
.li-hearing:before {
    content: "\e8af";
}
.li-volume-high:before {
    content: "\e8b0";
}
.li-volume-medium:before {
    content: "\e8b1";
}
.li-volume-low:before {
    content: "\e8b2";
}
.li-volume:before {
    content: "\e8b3";
}
.li-mute:before {
    content: "\e8b4";
}
.li-lan:before {
    content: "\e8b5";
}
.li-lan2:before {
    content: "\e8b6";
}
.li-wifi:before {
    content: "\e8b7";
}
.li-wifi-lock:before {
    content: "\e8b8";
}
.li-wifi-blocked:before {
    content: "\e8b9";
}
.li-wifi-mid:before {
    content: "\e8ba";
}
.li-wifi-low:before {
    content: "\e8bb";
}
.li-wifi-low2:before {
    content: "\e8bc";
}
.li-wifi-alert:before {
    content: "\e8bd";
}
.li-wifi-alert-mid:before {
    content: "\e8be";
}
.li-wifi-alert-low:before {
    content: "\e8bf";
}
.li-wifi-alert-low2:before {
    content: "\e8c0";
}
.li-stream:before {
    content: "\e8c1";
}
.li-stream-check:before {
    content: "\e8c2";
}
.li-stream-error:before {
    content: "\e8c3";
}
.li-stream-alert:before {
    content: "\e8c4";
}
.li-communication:before {
    content: "\e8c5";
}
.li-communication-crossed:before {
    content: "\e8c6";
}
.li-broadcast:before {
    content: "\e8c7";
}
.li-antenna:before {
    content: "\e8c8";
}
.li-satellite:before {
    content: "\e8c9";
}
.li-satellite2:before {
    content: "\e8ca";
}
.li-mic:before {
    content: "\e8cb";
}
.li-mic-mute:before {
    content: "\e8cc";
}
.li-mic2:before {
    content: "\e8cd";
}
.li-spotlights:before {
    content: "\e8ce";
}
.li-hourglass:before {
    content: "\e8cf";
}
.li-loading:before {
    content: "\e8d0";
}
.li-loading2:before {
    content: "\e8d1";
}
.li-loading3:before {
    content: "\e8d2";
}
.li-refresh:before {
    content: "\e8d3";
}
.li-refresh2:before {
    content: "\e8d4";
}
.li-undo:before {
    content: "\e8d5";
}
.li-redo:before {
    content: "\e8d6";
}
.li-jump2:before {
    content: "\e8d7";
}
.li-undo2:before {
    content: "\e8d8";
}
.li-redo2:before {
    content: "\e8d9";
}
.li-sync:before {
    content: "\e8da";
}
.li-repeat-one2:before {
    content: "\e8db";
}
.li-sync-crossed:before {
    content: "\e8dc";
}
.li-sync2:before {
    content: "\e8dd";
}
.li-repeat-one3:before {
    content: "\e8de";
}
.li-sync-crossed2:before {
    content: "\e8df";
}
.li-return:before {
    content: "\e8e0";
}
.li-return2:before {
    content: "\e8e1";
}
.li-refund:before {
    content: "\e8e2";
}
.li-history:before {
    content: "\e8e3";
}
.li-history2:before {
    content: "\e8e4";
}
.li-self-timer:before {
    content: "\e8e5";
}
.li-clock:before {
    content: "\e8e6";
}
.li-clock2:before {
    content: "\e8e7";
}
.li-clock3:before {
    content: "\e8e8";
}
.li-watch:before {
    content: "\e8e9";
}
.li-alarm2:before {
    content: "\e8ea";
}
.li-alarm-add2:before {
    content: "\e8eb";
}
.li-alarm-remove:before {
    content: "\e8ec";
}
.li-alarm-check:before {
    content: "\e8ed";
}
.li-alarm-error:before {
    content: "\e8ee";
}
.li-timer:before {
    content: "\e8ef";
}
.li-timer-crossed:before {
    content: "\e8f0";
}
.li-timer2:before {
    content: "\e8f1";
}
.li-timer-crossed2:before {
    content: "\e8f2";
}
.li-download:before {
    content: "\e8f3";
}
.li-upload:before {
    content: "\e8f4";
}
.li-download2:before {
    content: "\e8f5";
}
.li-upload2:before {
    content: "\e8f6";
}
.li-enter-up:before {
    content: "\e8f7";
}
.li-enter-down:before {
    content: "\e8f8";
}
.li-enter-left:before {
    content: "\e8f9";
}
.li-enter-right:before {
    content: "\e8fa";
}
.li-exit-up:before {
    content: "\e8fb";
}
.li-exit-down:before {
    content: "\e8fc";
}
.li-exit-left:before {
    content: "\e8fd";
}
.li-exit-right:before {
    content: "\e8fe";
}
.li-enter-up2:before {
    content: "\e8ff";
}
.li-enter-down2:before {
    content: "\e900";
}
.li-enter-vertical:before {
    content: "\e901";
}
.li-enter-left2:before {
    content: "\e902";
}
.li-enter-right2:before {
    content: "\e903";
}
.li-enter-horizontal:before {
    content: "\e904";
}
.li-exit-up2:before {
    content: "\e905";
}
.li-exit-down2:before {
    content: "\e906";
}
.li-exit-left2:before {
    content: "\e907";
}
.li-exit-right2:before {
    content: "\e908";
}
.li-cli:before {
    content: "\e909";
}
.li-bug:before {
    content: "\e90a";
}
.li-code:before {
    content: "\e90b";
}
.li-file-code:before {
    content: "\e90c";
}
.li-file-image:before {
    content: "\e90d";
}
.li-file-zip:before {
    content: "\e90e";
}
.li-file-audio:before {
    content: "\e90f";
}
.li-file-video:before {
    content: "\e910";
}
.li-file-preview:before {
    content: "\e911";
}
.li-file-charts:before {
    content: "\e912";
}
.li-file-stats:before {
    content: "\e913";
}
.li-file-spreadsheet:before {
    content: "\e914";
}
.li-link:before {
    content: "\e915";
}
.li-unlink:before {
    content: "\e916";
}
.li-link2:before {
    content: "\e917";
}
.li-unlink2:before {
    content: "\e918";
}
.li-thumbs-up:before {
    content: "\e919";
}
.li-thumbs-down:before {
    content: "\e91a";
}
.li-thumbs-up2:before {
    content: "\e91b";
}
.li-thumbs-down2:before {
    content: "\e91c";
}
.li-thumbs-up3:before {
    content: "\e91d";
}
.li-thumbs-down3:before {
    content: "\e91e";
}
.li-share:before {
    content: "\e91f";
}
.li-share2:before {
    content: "\e920";
}
.li-share3:before {
    content: "\e921";
}
.li-magnifier:before {
    content: "\e922";
}
.li-file-search:before {
    content: "\e923";
}
.li-find-replace:before {
    content: "\e924";
}
.li-zoom-in:before {
    content: "\e925";
}
.li-zoom-out:before {
    content: "\e926";
}
.li-loupe:before {
    content: "\e927";
}
.li-loupe-zoom-in:before {
    content: "\e928";
}
.li-loupe-zoom-out:before {
    content: "\e929";
}
.li-cross:before {
    content: "\e92a";
}
.li-menu:before {
    content: "\e92b";
}
.li-list:before {
    content: "\e92c";
}
.li-list2:before {
    content: "\e92d";
}
.li-list3:before {
    content: "\e92e";
}
.li-menu2:before {
    content: "\e92f";
}
.li-list4:before {
    content: "\e930";
}
.li-menu3:before {
    content: "\e931";
}
.li-exclamation:before {
    content: "\e932";
}
.li-question:before {
    content: "\e933";
}
.li-check:before {
    content: "\e934";
}
.li-cross2:before {
    content: "\e935";
}
.li-plus:before {
    content: "\e936";
}
.li-minus:before {
    content: "\e937";
}
.li-percent:before {
    content: "\e938";
}
.li-chevron-up:before {
    content: "\e939";
}
.li-chevron-down:before {
    content: "\e93a";
}
.li-chevron-left:before {
    content: "\e93b";
}
.li-chevron-right:before {
    content: "\e93c";
}
.li-chevrons-expand-vertical:before {
    content: "\e93d";
}
.li-chevrons-expand-horizontal:before {
    content: "\e93e";
}
.li-chevrons-contract-vertical:before {
    content: "\e93f";
}
.li-chevrons-contract-horizontal:before {
    content: "\e940";
}
.li-arrow-up:before {
    content: "\e941";
}
.li-arrow-down:before {
    content: "\e942";
}
.li-arrow-left:before {
    content: "\e943";
}
.li-arrow-right:before {
    content: "\e944";
}
.li-arrow-up-right:before {
    content: "\e945";
}
.li-arrows-merge:before {
    content: "\e946";
}
.li-arrows-split:before {
    content: "\e947";
}
.li-arrow-divert:before {
    content: "\e948";
}
.li-arrow-return:before {
    content: "\e949";
}
.li-expand:before {
    content: "\e94a";
}
.li-contract:before {
    content: "\e94b";
}
.li-expand2:before {
    content: "\e94c";
}
.li-contract2:before {
    content: "\e94d";
}
.li-move:before {
    content: "\e94e";
}
.li-tab:before {
    content: "\e94f";
}
.li-arrow-wave:before {
    content: "\e950";
}
.li-expand3:before {
    content: "\e951";
}
.li-expand4:before {
    content: "\e952";
}
.li-contract3:before {
    content: "\e953";
}
.li-notification:before {
    content: "\e954";
}
.li-warning:before {
    content: "\e955";
}
.li-notification-circle:before {
    content: "\e956";
}
.li-question-circle:before {
    content: "\e957";
}
.li-menu-circle:before {
    content: "\e958";
}
.li-checkmark-circle:before {
    content: "\e959";
}
.li-cross-circle:before {
    content: "\e95a";
}
.li-plus-circle:before {
    content: "\e95b";
}
.li-circle-minus:before {
    content: "\e95c";
}
.li-percent-circle:before {
    content: "\e95d";
}
.li-arrow-up-circle:before {
    content: "\e95e";
}
.li-arrow-down-circle:before {
    content: "\e95f";
}
.li-arrow-left-circle:before {
    content: "\e960";
}
.li-arrow-right-circle:before {
    content: "\e961";
}
.li-chevron-up-circle:before {
    content: "\e962";
}
.li-chevron-down-circle:before {
    content: "\e963";
}
.li-chevron-left-circle:before {
    content: "\e964";
}
.li-chevron-right-circle:before {
    content: "\e965";
}
.li-backward-circle:before {
    content: "\e966";
}
.li-first-circle:before {
    content: "\e967";
}
.li-previous-circle:before {
    content: "\e968";
}
.li-stop-circle:before {
    content: "\e969";
}
.li-play-circle:before {
    content: "\e96a";
}
.li-pause-circle:before {
    content: "\e96b";
}
.li-next-circle:before {
    content: "\e96c";
}
.li-last-circle:before {
    content: "\e96d";
}
.li-forward-circle:before {
    content: "\e96e";
}
.li-eject-circle:before {
    content: "\e96f";
}
.li-crop:before {
    content: "\e970";
}
.li-frame-expand:before {
    content: "\e971";
}
.li-frame-contract:before {
    content: "\e972";
}
.li-focus:before {
    content: "\e973";
}
.li-transform:before {
    content: "\e974";
}
.li-grid:before {
    content: "\e975";
}
.li-grid-crossed:before {
    content: "\e976";
}
.li-layers:before {
    content: "\e977";
}
.li-layers-crossed:before {
    content: "\e978";
}
.li-toggle:before {
    content: "\e979";
}
.li-rulers:before {
    content: "\e97a";
}
.li-ruler:before {
    content: "\e97b";
}
.li-funnel:before {
    content: "\e97c";
}
.li-flip-horizontal:before {
    content: "\e97d";
}
.li-flip-vertical:before {
    content: "\e97e";
}
.li-flip-horizontal2:before {
    content: "\e97f";
}
.li-flip-vertical2:before {
    content: "\e980";
}
.li-angle:before {
    content: "\e981";
}
.li-angle2:before {
    content: "\e982";
}
.li-subtract:before {
    content: "\e983";
}
.li-combine:before {
    content: "\e984";
}
.li-intersect:before {
    content: "\e985";
}
.li-exclude:before {
    content: "\e986";
}
.li-align-center-vertical:before {
    content: "\e987";
}
.li-align-right:before {
    content: "\e988";
}
.li-align-bottom:before {
    content: "\e989";
}
.li-align-left:before {
    content: "\e98a";
}
.li-align-center-horizontal:before {
    content: "\e98b";
}
.li-align-top:before {
    content: "\e98c";
}
.li-square:before {
    content: "\e98d";
}
.li-plus-square:before {
    content: "\e98e";
}
.li-minus-square:before {
    content: "\e98f";
}
.li-percent-square:before {
    content: "\e990";
}
.li-arrow-up-square:before {
    content: "\e991";
}
.li-arrow-down-square:before {
    content: "\e992";
}
.li-arrow-left-square:before {
    content: "\e993";
}
.li-arrow-right-square:before {
    content: "\e994";
}
.li-chevron-up-square:before {
    content: "\e995";
}
.li-chevron-down-square:before {
    content: "\e996";
}
.li-chevron-left-square:before {
    content: "\e997";
}
.li-chevron-right-square:before {
    content: "\e998";
}
.li-check-square:before {
    content: "\e999";
}
.li-cross-square:before {
    content: "\e99a";
}
.li-menu-square:before {
    content: "\e99b";
}
.li-prohibited:before {
    content: "\e99c";
}
.li-circle:before {
    content: "\e99d";
}
.li-radio-button:before {
    content: "\e99e";
}
.li-ligature:before {
    content: "\e99f";
}
.li-text-format:before {
    content: "\e9a0";
}
.li-text-format-remove:before {
    content: "\e9a1";
}
.li-text-size:before {
    content: "\e9a2";
}
.li-bold:before {
    content: "\e9a3";
}
.li-italic:before {
    content: "\e9a4";
}
.li-underline:before {
    content: "\e9a5";
}
.li-strikethrough:before {
    content: "\e9a6";
}
.li-highlight:before {
    content: "\e9a7";
}
.li-text-align-left:before {
    content: "\e9a8";
}
.li-text-align-center:before {
    content: "\e9a9";
}
.li-text-align-right:before {
    content: "\e9aa";
}
.li-text-align-justify:before {
    content: "\e9ab";
}
.li-line-spacing:before {
    content: "\e9ac";
}
.li-indent-increase:before {
    content: "\e9ad";
}
.li-indent-decrease:before {
    content: "\e9ae";
}
.li-text-wrap:before {
    content: "\e9af";
}
.li-pilcrow:before {
    content: "\e9b0";
}
.li-direction-ltr:before {
    content: "\e9b1";
}
.li-direction-rtl:before {
    content: "\e9b2";
}
.li-page-break:before {
    content: "\e9b3";
}
.li-page-break2:before {
    content: "\e9b4";
}
.li-sort-alpha-asc:before {
    content: "\e9b5";
}
.li-sort-alpha-desc:before {
    content: "\e9b6";
}
.li-sort-numeric-asc:before {
    content: "\e9b7";
}
.li-sort-numeric-desc:before {
    content: "\e9b8";
}
.li-sort-amount-asc:before {
    content: "\e9b9";
}
.li-sort-amount-desc:before {
    content: "\e9ba";
}
.li-sort-time-asc:before {
    content: "\e9bb";
}
.li-sort-time-desc:before {
    content: "\e9bc";
}
.li-sigma:before {
    content: "\e9bd";
}
.li-pencil-line:before {
    content: "\e9be";
}
.li-hand:before {
    content: "\e9bf";
}
.li-pointer-up:before {
    content: "\e9c0";
}
.li-pointer-right:before {
    content: "\e9c1";
}
.li-pointer-down:before {
    content: "\e9c2";
}
.li-pointer-left:before {
    content: "\e9c3";
}
.li-finger-tap:before {
    content: "\e9c4";
}
.li-fingers-tap:before {
    content: "\e9c5";
}
.li-reminder:before {
    content: "\e9c6";
}
.li-fingers-crossed:before {
    content: "\e9c7";
}
.li-fingers-victory:before {
    content: "\e9c8";
}
.li-gesture-zoom:before {
    content: "\e9c9";
}
.li-gesture-pinch:before {
    content: "\e9ca";
}
.li-fingers-scroll-horizontal:before {
    content: "\e9cb";
}
.li-fingers-scroll-vertical:before {
    content: "\e9cc";
}
.li-fingers-scroll-left:before {
    content: "\e9cd";
}
.li-fingers-scroll-right:before {
    content: "\e9ce";
}
.li-hand2:before {
    content: "\e9cf";
}
.li-pointer-up2:before {
    content: "\e9d0";
}
.li-pointer-right2:before {
    content: "\e9d1";
}
.li-pointer-down2:before {
    content: "\e9d2";
}
.li-pointer-left2:before {
    content: "\e9d3";
}
.li-finger-tap2:before {
    content: "\e9d4";
}
.li-fingers-tap2:before {
    content: "\e9d5";
}
.li-reminder2:before {
    content: "\e9d6";
}
.li-gesture-zoom2:before {
    content: "\e9d7";
}
.li-gesture-pinch2:before {
    content: "\e9d8";
}
.li-fingers-scroll-horizontal2:before {
    content: "\e9d9";
}
.li-fingers-scroll-vertical2:before {
    content: "\e9da";
}
.li-fingers-scroll-left2:before {
    content: "\e9db";
}
.li-fingers-scroll-right2:before {
    content: "\e9dc";
}
.li-fingers-scroll-vertical3:before {
    content: "\e9dd";
}
.li-border-style:before {
    content: "\e9de";
}
.li-border-all:before {
    content: "\e9df";
}
.li-border-outer:before {
    content: "\e9e0";
}
.li-border-inner:before {
    content: "\e9e1";
}
.li-border-top:before {
    content: "\e9e2";
}
.li-border-horizontal:before {
    content: "\e9e3";
}
.li-border-bottom:before {
    content: "\e9e4";
}
.li-border-left:before {
    content: "\e9e5";
}
.li-border-vertical:before {
    content: "\e9e6";
}
.li-border-right:before {
    content: "\e9e7";
}
.li-border-none:before {
    content: "\e9e8";
}
.li-ellipsis:before {
    content: "\e9e9";
}

