/*------------------------------------------------------------------

    [Bootstrap changes]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

.invert{
        
    label{
        color: $invert-color-heading;
    }
    
    .form-text{
        color: $invert-color-secondary;
    }
    
    .form-control{
        border-color: $invert-form-border;
        background: $invert-form-background;
        color: $invert-color;

        &:focus{
            border-color: $invert-form-focus;
        }

        &:disabled,[readonly]{
            color: $invert-color-secondary;
            background: $invert-form-background-disabed;
        }
    }
    
    select{
        &.form-control{
            > option{
                color: $color;                    
            }
        }
    }
    
    .custom-control{
        .custom-control-input{
            &:disabled ~ .custom-control-label:before{
                background-color: $invert-form-background-disabed;
            }
        }
        .custom-control-label:before{
            background-color: $invert-form-background;
        }
    }
    .custom-select{
        background-color: $invert-form-background;
        border-color: $invert-form-border;
        color: $invert-color;
    }
    
    .input-group-prepend .input-group-text, 
    .input-group-append .input-group-text{
        border-color: $invert-form-border;
        background: $invert-form-background;
        color: $invert-color;
    }
    
    .custom-file-control{
        background: $invert-form-background;
        border-color: $invert-form-border;
        color: $invert-color;
        
        &:before{
            color: $invert-color;
            background: darken($invert-form-background,5%);
            border-color: $invert-form-border;
        }
    }
    
    .list-group{
        .list-group-item{
            color: $invert-color;
            border-color: $invert-border-color;
            background: $invert-base-skin-background;
            
            &.disabled,&:disabled{
                color: $invert-color-secondary;
            }
            
            &.active{
                background: $primary-background;                
            }
            
            &.list-group-item-action{
                &:hover,&:focus{
                    background: darken($invert-base-skin-background, 5%);                                        
                }
                &.active{
                    background: $primary-background;
                    color: $primary-color;
                }
            }
                                    
        }
    }
    
    .btn{
        &.btn-outline-secondary{
            color: $invert-base-skin-color;
        }        
        @include btn-build(warning, $invert-base-skin-background, $warning-background, $warning-background);
        @include btn-build(light, $invert-base-skin-background, $invert-base-skin-color, $invert-border-color);                
    }
}

.modal-content{
    &.invert{
        background: $invert-base-skin-background;
        color: $invert-color;
        
        .modal-header .close{
            color: $invert-color;
        }
    }
}

// card
.card{
    &.invert{
        background: $invert-sidepanel-background;
        color: $invert-color;
        
        .card-header{     
            background: rgba(0,0,0,0.2);
            .card-title{
                color: $invert-color-heading;
            }
        }
        .card-body{            
            .card-title{
                color: $invert-color-heading;
            }
            .card-inner-container{
                background: rgba(0,0,0,0.1);                
            }
        }
        .card-footer{
            background: rgba(0,0,0,0.2);
        }
        
        &.card-inner-container--up{
            .card-inner-container{
                background: $invert-sidepanel-background;
            }            
        }
        
        .nav-tabs .nav-link{            
            color: $invert-color;

            &:hover:not(.active),&:active:not(.active){
                border-color: transparent;        
            }

            &.active{
                color: $invert-color;
                background: $invert-sidepanel-background;
                border-color: rgba(0,0,0,0.1);
                border-bottom-color: $invert-sidepanel-background;
            }

            &.disabled{
                color: $invert-color-secondary;
            }
        }
        .nav-pills .nav-link{
            color: $invert-color;
            
            &.active{
                background: $primary-background;
                color: $primary-color;
            }
            
            &.disabled{
                color: $invert-color-secondary;
            }
        }
    }
}
// end card

// dropdowns
.invert{
    .dropdown-menu{            
        border-color: $invert-border-color;
        background: darken($invert-base-skin-background,3%);
        color: $invert-color;

        .dropdown-item{
            color: $invert-color;
            
            &:hover{
                background: rgba(0,0,0,0.2);
            }
            
            &.active{                
                background: darken($invert-base-skin-background,5%);
            }
        }

        .dropdown-divider{
            border-color: $invert-border-color;
        }    
    }
}
// end dropdowns

// table
.invert{
    .table{
        color: $invert-color;
        background-color: $invert-sidepanel-background;            

        th,td{        
            border-top: 1px solid $invert-sidepanel-border;
            color: $invert-color;
        }

        thead{
            th{
                border-bottom: 1px solid $invert-border-color;            
                color: $invert-color-heading;
            }
        }     

        &.table-dark{
            color: $invert-color;
            background-color: darken($invert-sidepanel-background,2%);        

            th{
                color: $invert-color-heading;
            }

            th,td{
                border-color: darken($invert-sidepanel-border,2%);
            }
            thead{
                th{
                    border-color: darken($invert-sidepanel-border,2%);
                }
            }        
        }

        .thead-dark{
            th{
                color: $invert-color;
                background-color: darken($invert-sidepanel-background,2%); 
                border-color: darken($invert-sidepanel-border,2%);
            }
        }

        .thead-light{
            th{
                background: rgba(0,0,0,0.05);
            }
        }

        &.table-striped{
            tbody {
                tr:nth-of-type(odd){
                    background: rgba(0,0,0,0.05);
                }
            }
        } 
        &.table-bordered{
            border-color: $invert-border-color;
            th,td{
                border-color: $invert-border-color;
            }            
        }
        &.table-hover{
            tbody{
                tr{
                    @if $animation != 0 {
                        @include transition(all $animation ease-in-out);
                    }
                    &:hover{
                        background: rgba(0,0,0,0.08);
                    }
                }
            }
        } 
        &.table-indent-rows{
            td,th{
                border-color: $invert-border-color;
                
                &:last-child,&:first-child{
                    border-color: $invert-border-color;
                }
            }
            thead{
                th{
                    border: 0px;
                    color: $invert-color-secondary;
                }
            }
            tbody{
                td{
                    background: rgba(0,0,0,0.05);
                }
            }
        }
    }
}

.table{
    .thead-dark{
        .custom-control{
            .custom-control-input{
                &:disabled ~ .custom-control-label:before{
                    background-color: $invert-form-background-disabed;
                }
            }
            .custom-control-label:before{
                background-color: $invert-form-background;
            }
        }
    }
    &.table-dark{
        .custom-control{
            .custom-control-input{
                &:disabled ~ .custom-control-label:before{
                    background-color: $invert-form-background-disabed;
                }
            }
            .custom-control-label:before{
                background-color: $invert-form-background;
            }
        }
    }
}

// pagination
.invert{
    .pagination{
        .page-item{
            .page-link{
                color: $invert-color;
                border-color: $invert-border-color;
                background: $invert-base-skin-background;            

                &:hover{
                    background: darken($invert-base-skin-background, 5%);
                }
            }
            &.disabled{
                .page-link{
                    color: $invert-color-secondary;
                }
            }        
        }    

    }
}
// end pagination

// end 