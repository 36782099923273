/*------------------------------------------------------------------

    [Logo styles]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]    
    
    
    [Table of contents]
    
    1. Logo holder
    2. Media for logo holder

-------------------------------------------------------------------*/

/*-----
    [Logo holder]
    
    Container for logo    
    
    + .logo-holder              - wrapper
        & .logo-holder--xl      - [modify] extra large logo container
        & .logo-holder--lg      - [modify] large logo container
        & .logo-holder--wide    - [modify] wide logo container

        + .logo                 - container for image logo
        + .logo-text            - container for text logo        
-----*/

.logo-holder{
    float: left;    
    width: 250px;
    height: 59px;
    position: relative;
    
    .logo,.logo-text{
        float: left;    
        width: 250px;
        height: 59px;        
    }
    
    .logo{    
        background: $logo-image;
        background-size: 250px 60px;
        overflow: hidden;
        font-size: 0px;
        text-indent: 99999px;
        @include image-2x($logo-image-2x,250,60);
    }

    .logo-text{
        line-height: 20px;
        font-size: 30px;
        font-weight: 300;
        padding: 20px 0px 20px 20px;
        color: $color-heading;    
        @if $animation != 0 {
            @include transition(color $animation ease-in-out);
        }

        &:hover{
            text-decoration: none;
            color: darken($color-heading,5%);        
        }

        strong{
            font-weight: 900;
        }
    }
    
    /*-----
        [.logo-holder--xl extra large logo container]
    -----*/
    &--xl{
        width: 500px;
        height: 40px;
        
        .logo-text{
            width: 500px;
            height: 40px;
            padding: 0px;
            line-height: 40px;
            font-size: 32px;
            text-align: center;            
        }
    }
    
    /*-----
        [.logo-holder--lg large logo container]
    -----*/
    &--lg{  
        width: 100%;
        height: 40px;
        
        .logo-text{
            width: 100%;
            height: 40px;
            padding: 0px;
            line-height: 40px;
            font-size: 24px;
            text-align: center;
        }
    }
    
    /*-----
        [.logo-holder--wide wide logo container]
    -----*/
    &--wide{
        width: 100%;
        
        .logo-text{
            width: 100%;
        }
    }
    &.text-left{
        &.logo-holder--xl{
            .logo-text{
                text-align: left;
            }
        }
    }
}

/*-----
    [RW button support in logo holder]
-----*/
.page{
    .logo-holder{
        .rw-btn{
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }        
}


/*-----
    [Media for logo holder]
-----*/
@include media-breakpoint-down(md) {
    .page{
        .logo-holder{
            width: 105px;
            
            .logo,.logo-text{
                width: 60px;
                overflow: hidden;                
            }
            
            &--xl{
                width: 100%;
                
                .logo,.logo-text{
                    width: 100%;
                }
            }
            &--lg{
                width: 100%;            
                
                .logo,.logo-text{
                    width: 100%;
                }
            }
            &--wide{
                width: 100%;
                
                .logo,.logo-text{
                    width: 100%;
                }
            }
        }
    }
}