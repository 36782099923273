/*------------------------------------------------------------------

    [Widgets]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

.widget{
    &.invert{
        border: 0px;
        background: $invert-base-skin-background;
        color: $invert-color-heading;        

        .widget__icon_layer{           
            span{                        
                color: rgba($invert-color,0.1);
            }            
        }
        
        .widget__icon{            
            color: $invert-color-heading;
        }
        
        .widget__title{                        
            color: $invert-color-heading;
        }
        .widget__subtitle{
            color: $invert-color-secondary;
        }
        
        .widget__informer{
            background: rgba(0,0,0,0.1);
        }
        
        &.widget--button{
            border: 1px dashed $invert-color-secondary;
            background: transparent;            

            .widget__icon{                
                color: $invert-color-secondary;                
            }

            &:hover{
                background: $invert-base-skin-background;
                border: 1px dashed $invert-color;

                .widget__icon{
                    color: $invert-color;
                }
            }
        }
    }    
}

.informer{
    &.invert{
        border: 0px;
        background: $invert-base-skin-background;
        color: $invert-color;
        
        .informer__num{
            color: $invert-color-heading;
        }
        .informer__title{
            color: $invert-color;
        }
        .informer__icon{
            color: $invert-color-heading;
        }
    }
}

.invert{
    .widget{
        &.widget--button{
            border: 1px dashed $invert-color-secondary;
            background: transparent;            

            .widget__icon{                
                color: $invert-color-secondary;                
            }

            &:hover{
                background: $invert-base-skin-background;
                border: 1px dashed $invert-color;

                .widget__icon{
                    color: $invert-color;
                }
            }
        }
        
        &--invert-by-parent{
            border: 0px;
            background: $invert-base-skin-background;
            color: $invert-color-heading;        

            .widget__icon_layer{           
                span{                        
                    color: rgba($invert-color,0.1);
                }            
            }

            .widget__icon{            
                color: $invert-color-heading;
            }

            .widget__title{                        
                color: $invert-color-heading;
            }
            .widget__subtitle{
                color: $invert-color-secondary;
            }

            .widget__informer{
                background: rgba(0,0,0,0.1);
            }

            &.widget--button{
                border: 1px dashed $invert-color-secondary;
                background: transparent;            

                .widget__icon{                
                    color: $invert-color-secondary;                
                }

                &:hover{
                    background: $invert-base-skin-background;
                    border: 1px dashed $invert-color;

                    .widget__icon{
                        color: $invert-color;
                    }
                }
            }
        }
    }
}