/*------------------------------------------------------------------

    [Personal styles]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

    We recommend you to use this file for your stylesheets.
    
-------------------------------------------------------------------*/

html body {
    min-height: 100% !important;
}

html {
    box-sizing: border-box !important;
    height: 100% !important;
}