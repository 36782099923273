.mt-9vh {
  margin-top: 9vh;
}

.registercard {
  width: 28rem;
}

.register-header {
  border-bottom: 1px solid #eee;
}
