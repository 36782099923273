.mt-9vh {
  margin-top: 9vh;
}

.logincard {
  width: 28rem;
}

.login-header {
  border-bottom: 1px solid #eee;
}
