/*------------------------------------------------------------------

    [Elements]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]

-------------------------------------------------------------------*/

// invert page heading
.page-heading{
    &.invert{
        background: darken($invert-content-background,2%);
        border-bottom-color: $invert-border-color;    
        
        .page-heading__container{
            .title{
                color: $invert-color-heading;
            }
            p.caption{
                color: $invert-color-secondary;
            }
        }
        
        .breadcrumb{
            border-top: 1px solid $invert-border-color;
            background: darken($invert-content-background,3%);        

            .breadcrumb-item{
                > a{color: $invert-color-secondary;}            
                &.active{color: $invert-color;}
                &:before{color: $invert-color-secondary;}
            }
        }
    }
}
.invert{
    .page-heading{
        background: $invert-sidepanel-background;
        border-bottom-color: $invert-border-color;    

        .page-heading__container{
            .title{
                color: $invert-color-heading;
            }
            p.caption{
                color: $invert-color-secondary;
            }
        }

        .breadcrumb{
            border-top: 1px solid $invert-border-color;
            background: darken($invert-sidepanel-background,1%);        

            .breadcrumb-item{
                > a{color: $invert-color-secondary;}            
                &.active{color: $invert-color;}
                &:before{color: $invert-color-secondary;}
            }
        }
    }
}
// end