.underline-dotted {
  border-bottom: 1px dotted;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  animation: spinner .6s linear infinite;
}

.progress-thin {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.server-disabled .server-title {
  color: grey;
}