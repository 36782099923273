.select2-selection--multiple {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    min-height: 40px;

    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
        display: inline-block;
        overflow: hidden;
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
}

.select2-search--inline {
    float: left;

    .select2-search__field {
        line-height: $line-height;
        padding: 5px 0px 5px 4px;
        box-sizing: border-box;
        border: none;
        font-size: 100%;
        margin-top: 0px;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
        
    }
}
