.select2-selection--multiple {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    cursor: text;

    .select2-selection__rendered {
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        padding: 4px 5px 0px 5px;
        width: 100%;

        li {
            list-style: none;
        }
    }

    .select2-selection__placeholder {
        color: #999;

        margin-top: 5px;

        float: left;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-top: 5px;
        margin-right: 10px;
    }

    .select2-selection__choice {
        background-color: $primary-background;   
        color: $primary-color;   
        line-height: $line-height;
        border-radius: $border-radius;
        cursor: default;
        float: left;        
        padding: 5px 10px;
        margin: 0px 5px 5px 0px;
    }

    .select2-selection__choice__remove {
        color: $primary-color;
        cursor: pointer;

        display: inline-block;
        font-weight: bold;

        margin-right: 5px;

        &:hover {
            color: $primary-color;
        }
    }
}

&[dir="rtl"] {
    .select2-selection--multiple {
        .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
            float: right;
        }

        .select2-selection__choice {
            margin-left: 5px;
            margin-right: auto;
        }

        .select2-selection__choice__remove {
            margin-left: 2px;
            margin-right: auto;
        }
    }
}

&.select2-container--focus {
    .select2-selection--multiple {
        border: solid $form-focus 1px;
        outline: 0;
    }
}

&.select2-container--disabled {
    .select2-selection--multiple {
        background: $form-background-disabled;
        color: $color-secondary;
        cursor: default;
    }

    .select2-selection__choice__remove {
        display: none;
    }
}
