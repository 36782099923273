/*------------------------------------------------------------------

    [Login/Registration]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]    
    

    [Table of contents]
    
    1. Login container

-------------------------------------------------------------------*/

/*-----
    [Login container]

    + .login-container                          - container for all stuff
        + .login-container__buttons             - container for buttons
            &.login-container__buttons--right   - right position of container
        + .content                              - container content

-----*/
.important-container{
    &.login-container{
        position: relative;

        .login-container__buttons{
            position: absolute;
            left: 20px;
            top: 20px;

            &--right{
                left: auto;
                right: 20px;
            }
        }

        > .content{
            width: 100%;
            padding: 0px 20%;
        }
    }
}



/*-----
    [Media for login container]
-----*/
@include media-breakpoint-down(lg) {
    .page__content{ 
        > .important-container{
            &.login-container{
                .content{
                    padding: 0px 15%;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page__content{ 
        > .important-container{
            &.login-container{
                width: 100%;

                .content{
                    padding: 0px 10%;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .page__content{ 
        > .important-container{
            &.login-container{
                flex: none;
                display: block;
                padding-top: 80px;
            }
        }
    }
}
// end