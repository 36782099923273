/*------------------------------------------------------------------

    [Boostatrap styles changes]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]    
    
    
    [Table of contents]
    
    1. Form elements
    2. Card
    3. Nav bar and pills
    4. List group
    5. Btn group
    6. Backgrounds
    7. Buttons
    8. Input groups
    9. Dropdowns
    10. Alerts
    11. Badges
    12. Modals
    13. Popover
    14. Validation
    15. Tables
    16. Pagination
    17. Progress

-------------------------------------------------------------------*/


/*----- 
    [Form elements]
------*/
.form-group{
    margin-bottom: 15px;
    
    .col-form-legend{
        font-weight: 500;
        line-height: $line-height;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    label{
        margin-bottom: 5px;
        font-weight: 500;
        font-size: $font-size - 1;
        color: $color;
        
        &.col-form-label{
            margin-bottom: 0px;
            line-height: $line-height;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    
    .form-text{
        margin-top: 5px;
        font-size: $font-size - 2;
        font-weight: 400;
        color: $color-secondary;
        
        &--primary{
            background: $primary-background;
            color: $primary-color;
            display: inline-block;
            padding: 0px 5px;
            border-radius: $border-radius;
        }
    }
            
    .custom-control{
        margin-bottom: 0px;
        
        .custom-control-description{
            font-weight: 400;
        }
    }    
}

.form-control{
    line-height: $line-height;
    font-size: $font-size;
    padding: 9px;
    border-color: $form-border;
    background: $form-background;
    @if $animation != 0 {
        @include transition(border $animation ease-in-out);
    }
    &:focus{
        box-shadow: none;
        border-color: $form-focus;
    }

    &:disabled,[readonly]{
        color: $color-secondary;
        background: $form-background-disabled;
    }
}

legend{
    font-size: $font-size;
}

select{
    &.form-control:not([size]):not([multiple]){
        height: 40px;
    }
    &.form-control-sm{
        height: auto;
    }
}

.form-check{
    margin-bottom: 5px;
    
    label{
        font-size: $font-size - 1;
    }
}

.form-inline{
    .form-group{
        label{
            margin-bottom: 0px;
        }
        .form-text{
            margin-top: 0px;
        }
    }
}

.custom-control{
    margin-bottom: 10px;
    padding-left: $line-height + 10px;
    
    .custom-control-input{
        &:focus ~ .custom-control-label:before{
            box-shadow: none;
        }
        &:checked ~ .custom-control-label:before{
            background-color: $form-focus;
        }
        &:active ~ .custom-control-label:before{
            background-color: lighten($form-focus,30%);
        }
        &:disabled ~ .custom-control-label:before{
            background-color: $form-background-disabled;
        }
    }
    .custom-control-label{
        font-size: $font-size - 1;
        line-height: $line-height;
        
        &:before{
            background-color: darken($form-background,5%);
            width: $line-height;
            height: $line-height;
            top: 0px;
            left: -30px;
            @if $animation != 0 {
                @include transition(background $animation ease-in-out);
            }
        }
        &:after{
            top: 0px;
            left: -30px;
            width: $line-height;
            height: $line-height;
        }
    }    
}

.custom-select{
    line-height: $line-height;
    font-size: $font-size;
    padding: 9px 30px 9px 9px;
    border-color: $form-border;
    border-radius: $border-radius;
    height: auto;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file{
    height: auto;
    
    .custom-file-input{
        height: 40px;
        
        &:focus ~ .custom-file-label{
            box-shadow: none;
        }
    }
    .custom-file-label{
        padding: 9px;
        line-height: $line-height;
        height: auto;
        
        &:after{
            height: auto;
            line-height: $line-height;
            padding: 9px;
        }
    }
}


/*----- 
    [Card]
------*/
.card{
    margin-bottom: 30px;
    border-radius: $border-radius;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @if $animation != 0 {
        @include transition(all $animation ease-in-out);
    }
    @if $shadows != 0 {
        box-shadow: 0 1px 10px 0 rgba(0,0,0,.1);
    }
    
    .card-header{
        background: rgba(0,0,0,0.05);
        border-color: rgba(0, 0, 0, 0.1);
        padding: 15px 20px;        
        
        .card-title{
            margin-bottom: 0px;
            font-size: $font-size + 2;
            font-weight: 500;
            color: $color-heading;
            float: left;
        }
    }
    
    .card-body{
        padding: 20px;
        
        div.card-title{
            font-size: $font-size + 2;
            font-weight: 500;
        }
        .card-title{
            margin-bottom: 10px;            
            color: $color-heading;
        }
        .card-subtitle{
            margin-top: -5px;
            margin-bottom: 10px;
            color: $color-secondary;
        }
        
        // navigation support
        .navigation{
            padding: 0px;
        }
        .navigation--minimized{
            .navigation{
                padding: 10px;
            }
        }
        // end navigation support
        
        .card-inner-container{
            background: rgba(0,0,0,0.05);
            border-radius: $border-radius;
            padding: 20px;
            margin-bottom: 20px;
            border: 1px dashed rgba(0, 0, 0, 0.1);
            
            &--light{
                background: $base-skin-background;
            }
        }        
    }
    
    .card-footer{
        background: rgba(0,0,0,0.05);
        border-color: rgba(0, 0, 0, 0.1);
        padding: 15px 20px;
    }
    
    > .table{
        margin-bottom: 0px;
    }
    
    .card-container{
        position: absolute;
        right: 20px;
        top: 25px;
        z-index: 1;
    }
    
    &.card-inner-container--up{
        margin-top: 40px;   
        
        .card-inner-container{
            position: absolute;
            top: -40px;
            width: calc(100% - 40px);
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
        
        .card-body{
            padding-top: 60px;
        }                
        
        .card-container{
            right: 40px;
            top: -15px;
        }
    }
    
    &--expanded{
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 998;
        width: 100%;
        height: 100%;
        border: 0px;
        
        .card-body{
            flex: 0;
        }
        
        &.card-inner-container--up{
            margin-top: 0px;
            
            .card-body{                
                padding-top: 20px;                
            }
            
            .card-inner-container{
                width: 100%;
                margin-top: 0px;
                position: relative;
                top: 0px;  
                margin-bottom: 30px;
            }
            .card-container{
                top: 45px;
            }
        }
    }
}
.card-img-overlay{
    padding: 15px;
}
.card-group,.card-deck{
    margin-bottom: 30px;
}
.card-group{
    @if $shadows != 0 {
        box-shadow: 0px 0px 24px 1px rgba(0,0,0,0.1);    
    }
    .card{
        box-shadow: none;
    }
}


/*----- 
    [Nav bar and pills]
------*/
.card-header-tabs{
    margin-bottom: -15px;
    margin-left: 0px;
    margin-right: 0px;
}
.nav-tabs .nav-link{
    //border-radius: $border-radius;
    color: $color;
    padding: 9px 20px;
    
    &:hover:not(.active),&:active:not(.active){
        border-color: transparent;
        outline: none;
    }
    
    &:focus:not(.active){
        border-color: transparent;
    }
    
    &.active{
        color: $color;
    }
    
    &.disabled{
        color: $color-secondary;
    }
}

.nav-tabs-cardtop{
    margin-top: 70px;
    
    .nav-tabs{
        margin-top: -40px;
        margin-left: -1px;
    }
}

.card-header-pills{
    margin-right: 0px;
    margin-left: 0px;
}
.nav-pills .nav-link{
    border-radius: $border-radius;
    padding: 10px 15px;
    color: $color;
    
    &.disabled{
        color: $color-secondary;
    }
    
    &.active{
        background: $primary-background;
        color: $primary-color;
    }
}
.nav-pills .show > .nav-link{
    background: $primary-background;
    color: $primary-color;
}


/*----- 
    [List group]
------*/
@mixin list-group-build($name, $background, $color){
    &.list-group-item-#{$name}{        
        background: $background;
        border-color: darken($background,5%);
        color: $color;        
        
        &.list-group-item-action{
            &:hover:not(.disabled),&:focus:not(.disabled){
                background: darken($background,5%);
            }
        }
    }
}
.list-group{
    .list-group-item{
        color: $color;
        border-color: $border-color;
        padding: 10px 15px;
        @if $animation != 0 {
            @include transition(all $animation linear);
        }
        
        > span{
            &.fa{
                font-size: 14px;
                margin-right: 5px;
            }
        }
        
        &.active{
            background: $primary-background;
            color: $primary-color;
            border-color: $primary-background;
            
            h1,h2,h3,h4,h5,h6,small{
                color: $primary-color;
            }
        }
        &.list-group-item-action:focus:not(.disabled), 
        &.list-group-item-action:hover:not(.disabled){
            background: rgba(0,0,0,0.05);            
        }
        &.disabled,&:disabled{
            color: $color-secondary;
        }
        &.list-group-item-action{
            &.active{
                &:hover,&:focus{
                    background: $primary-background;
                }
            }
        }
        
        @include list-group-build(primary, $primary-background, $primary-color);
        @include list-group-build(secondary, $secondary-background, $secondary-color);
        @include list-group-build(success, $success-background, $success-color);
        @include list-group-build(danger, $danger-background, $danger-color);
        @include list-group-build(warning, $warning-background, $warning-color);
        @include list-group-build(info, $info-background, $info-color);
        @include list-group-build(light, $base-skin-background, $base-skin-color);
        @include list-group-build(dark, $dark-background, $dark-color);
    }
}


/*----- 
    [Btn group]
------*/
.btn-group{
    width: 100%;
}


/*----- 
    [Backgrounds]
------*/
.bg-primary{background: $primary-background !important;}
.bg-secondary{background: $secondary-background !important;}
.bg-success{background: $success-background !important;}
.bg-danger{background: $danger-background !important;}
.bg-warning{background: $warning-background !important;}
.bg-info{background: $info-background !important;}
.bg-light{background: $base-skin-background !important;}
.bg-dark{background: $dark-background !important;}


/*----- 
    [Buttons]
------*/
@mixin btn-build($name, $background, $color, $border: darken($background,3%)){
    &.btn-#{$name}{
        border-color: $border;
        background: $background;
        color: $color;

        &:hover{
            border-color: darken($border, 8%);
            background: darken($background, 5%);
            color: $color;
        }                
        
        &:not([disabled]):not(.disabled):active, 
        &:not([disabled]):not(.disabled).active{
            border-color: darken($border, 10%);
            background: darken($background, 3%);
            color: $color;
            box-shadow: 0px 0px 0px 2px fade-out($background,.8);
        }                
        
        &:focus,&.focus{
            border-color: darken($border, 3%);
            background: darken($background, 2%);
            color: $color;
            box-shadow: none;
        }        
    }
    &.btn-outline-#{$name}{
        border-color: $border;        
        @if $name == 'warning' or $name == 'light' {
        color: $color;
        } @else {
        color: $background;    
        }
        
        &:hover,&.active,&:active, &:active:focus{
            border-color: $border;
            background: $background;
            color: $color;
        }
        &:focus{
            border-color: darken($border, 12%);
            background: darken($background, 12%);
            color: $color;
        }
        &:focus,&.active,&:active, &:active:focus{
            @if $shadows != 0 {
            box-shadow: inset 0px 1px 2px rgba(0,0,0,0.1);
            }
        }
        
        &.disabled{
            border-color: $border;
            background: rgba($background, 0.1);
            
            &:hover{
                border-color: $border;
                background: rgba($background, 0.1);
                @if $name == 'warning' or $name == 'light' {
                color: $color;
                } @else {
                color: $background;    
                }
            }
        }
    }
}

.btn{
    padding: 9px 15px;
    line-height: $line-height;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    
    @include btn-build(primary, $primary-background, $primary-color);
    @include btn-build(secondary, $secondary-background, $secondary-color);
    @include btn-build(success, $success-background, $success-color);
    @include btn-build(danger, $danger-background, $danger-color);
    @include btn-build(warning, $warning-background, $warning-color);
    @include btn-build(info, $info-background, $info-color);
    @include btn-build(light, $base-skin-background, $base-skin-color, $border-color);
    @include btn-build(dark, $dark-background, $dark-color);
    
    &:disabled,&.disabled{
        cursor: default;
    }
    
    // icons support    
    [class^='li-'],.fa{
        font-size: 20px;
        line-height: 20px;
        float: left;
        text-align: center;
    }
    .fa{
        font-size: 14px;        
    }
    
    &.btn-lg{
        padding: 14px 20px;
        font-size: $font-size;
    }
    &.btn-sm{
        padding: 4px 10px;
        font-size: $font-size - 2;
        
        span[class^='li-']{
            font-size: 20px;        
        }
        span.fa{
            font-size: 14px;        
        }
    }
    &.btn-xs{
        line-height: $line-height - 2;
        padding: 0px 10px;
        font-size: $font-size - 3;
    }
    & + .dropdown-toggle-split{
        padding-left: 10px;
        padding-right: 10px;
    }
    
    &--icon{
        span{
            float: left;
            line-height: $line-height - 1;
            margin-right: 10px;
            
            &.fa{
                font-size: 14px;     
                line-height: $line-height - 1;
            }
        }                
        
        &.btn-lg{
            span{
                margin-right: 10px;
            }
        }
        
        &.btn--icon-stacked{
            position: relative;
            padding-left: 52px;
            
            span{
                position: absolute;
                left: 0px;
                top: 0px;
                width: 38px;
                height: 38px;
                line-height: 38px;
                background: rgba(0,0,0,0.05);
                text-align: center;
                                
                &.text{
                    font-family: "Oswald";
                    font-size: 13px;
                }
            }
            
            &.btn-lg{
                padding-left: 58px;
                
                span{
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                }
            }
            &.btn-sm{
                padding-left: 35px;
                
                span{
                    width: 28px;
                    height: 28px;
                    line-height: 27px;
                }
            }            
        }
        
        &.btn--icon-right{
            span{
                float: right;
                margin-right: 0px;
                margin-left: 5px;
            }
            
            &.btn-lg{
                span{
                    margin-right: 0px;
                    margin-left: 10px;
                }
            }
            
            &.btn--icon-stacked{
                padding-left: 15px;
                padding-right: 45px;
                
                span{
                    float: none;
                    left: auto;
                    right: 0px;                    
                }
                
                &.btn-lg{
                    padding-left: 20px;
                    padding-right: 58px;
                }
                &.btn-sm{
                    padding-left: 10px;
                    padding-right: 35px;
                }
            }
        }
    }
    
    &-icon{
        padding: 9px 0px;
        width: 40px;
        text-align: center;
        
        span{
            &[class^='li-'], &.fa{
                width: 100%;
            }
        }
        
        &.btn-lg{
            width: 50px;
            padding: 14px 0px;            
        }
        &.btn-sm{
            width: 30px;
            padding: 4px 0px;
        }
        &.btn-xs{
            width: 20px;
            padding: 0px;            
        }
    }
    
    &--anon{
        font-family: "Oswald";
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
button.close{
    cursor: pointer;
}


/*----- 
    [Input groups]
------*/
.input-group-prepend .input-group-text,
.input-group-append .input-group-text{
    background: $base-skin-background;
    border: 1px solid $border-color;
    color: $base-skin-color;
    line-height: $line-height;
    padding: 0px 10px;
    text-align: center;
    border-radius: $border-radius;
    
    *[class^="fa"]{
        font-size: 14px;
    }
    *[class^="li-"]{
        font-size: 20px;
    }
    
    .custom-checkbox,.custom-radio{
        margin: 0px;
        padding-left: 20px;
        
        .custom-control-label{
            display: block;
            
            &:before,&:after{
                left: -20px;
            }
        }
    }
}

.form-control-sm, 
.input-group-sm > .form-control, 
.input-group-sm > .input-group-append .input-group-text,
.input-group-sm > .input-group-prepend .input-group-text{
    padding: 4px 5px;
    font-size: $font-size - 2;
    line-height: 20px;
    height: 30px !important;
    border-radius: $border-radius;
}

.form-control-lg, 
.input-group-lg > .form-control, 
.input-group-lg > .input-group-append .input-group-text,
.input-group-lg > .input-group-prepend .input-group-text{
    padding: 14px 15px;
    font-size: $font-size + 2;
    line-height: 20px;
    border-radius: $border-radius;
}
.input-group{
    margin-bottom: 15px;
    
}
.form-group{
    > .input-group{
        margin-bottom: 0px;
    }
}


/*----- 
    [Dropdowns]
------*/
.dropdown-menu{
    padding: 5px 0px;
    margin: 7px 0px 7px;
    min-width: 200px;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
    border-color: $border-color;
    color: $color;
    @if $shadows != 0 {
        box-shadow: 0px 1px 12px 1px rgba(0,0,0,0.1);
    }
    
    .dropdown-item{        
        padding: 10px 15px 10px 30px;
        color: $color;
        outline: none;
        position: relative;
        @if $animation != 0 {
            @include transition(all $animation ease-in-out);
        }                
        
        &:after{
            content: " ";
            width: 3px;
            height: 3px;
            background: $color;
            position: absolute;
            left: 14px;
            top: 18px;
        }
        
        span{
            &.fa{
                font-size: 14px;
                line-height: $line-height - 1;
                float: left;
                margin-right: 10px;
                width: 20px;
                text-align: center;
            }
        }
        
        &:hover{
            padding-left: 35px;
            background: rgba(0,0,0,0.03);
        }        
        
        &.active{            
            background: $base-skin-background;
            
            &:hover{
                padding-left: 30px;
                background: rgba(0,0,0,0.03);
            }
            
            &:after{
                background: $primary-background;
            }
        }                
        
        &.disabled{
            background: transparent;
            opacity: 0.5;
        }
    }
    .dropdown-divider{
        border-color: $border-color;
    }
    .dropdown-header{
        padding: 10px 15px 10px;
    }
}


/*----- 
    [Alerts]
------*/
@mixin alert-build($name, $background, $color, $border: rgba(0,0,0,0.1)){
    &.alert-#{$name}{
        background: $background;
        color: $color;
        border-color: $border;
        
        hr{
            border-color: $border;
        }
    }
}

.alert{
    padding: 10px 15px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: $border-radius;
    
    .alert-heading{
        margin-top: 5px;
    }
    
    @include alert-build(primary, $primary-background, $primary-color);
    @include alert-build(secondary, $secondary-background, $secondary-color);
    @include alert-build(success, $success-background, $success-color);
    @include alert-build(danger, $danger-background, $danger-color);
    @include alert-build(warning, $warning-background, $warning-color);
    @include alert-build(info, $info-background, $info-color);
    @include alert-build(light, $base-skin-background, $base-skin-color, $border-color);
    @include alert-build(dark, $dark-background, $dark-color);
}


/*----- 
    [Badges]
------*/
@mixin badge-build($name, $background, $color, $border: rgba(0,0,0,0.1)){
    &.badge-#{$name}{
        background: rgba($background,0.1);
        color: $background;
        border-color: $border;
        
        &:after{
            background: $background;
        }
        
        &[hover]{
            &:hover{
                background: rgba($background,0.5);
            }
        }
        
    }
}
.badge{
    font-size: $font-size - 2;
    font-weight: 500;
    border-radius: $border-radius;
    padding: 0px 5px 0px 15px;
    line-height: inherit;
    position: relative;
    @if $animation != 0 {
        @include transition(background $animation ease-in-out);
    }
    
    &:after{
        content: " ";
        position: absolute;
        left: 7px;
        top: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
    }
        
    @include badge-build(primary, $primary-background, $primary-color);
    @include badge-build(secondary, $secondary-background, $secondary-color);
    @include badge-build(success, $success-background, $success-color);
    @include badge-build(danger, $danger-background, $danger-color);
    @include badge-build(warning, $warning-background, $warning-color);
    @include badge-build(info, $info-background, $info-color);
    @include badge-build(light, $base-skin-background, $base-skin-color, $border-color);
    @include badge-build(dark, $dark-background, $dark-color);
    
    &.badge-pill{
        border-radius: 1rem;
        padding: 0px 10px;
        
        &:after{
            display: none;
        }
    }
}


/*----- 
    [Modals]
------*/
.modal-content{
    border-color: rgba(0,0,0,0.1);
    border-radius: $border-radius;
    
    .modal-header{
        border-color: rgba(0,0,0,0.1);
        border-radius: $border-radius $border-radius 0px 0px;
    }
    .modal-footer{
        border-color: rgba(0,0,0,0.1);
        border-radius: 0px 0px $border-radius $border-radius;
    }
    
    &--bordered{
        border: 10px solid rgba(0,0,0,0.1);
    }
}


/*----- 
    [Popover]
------*/
.popover{
    line-height: $line-height;
    font-size: $font-size;
    border-radius: $border-radius;
    padding: 0px;
    
    .popover-header{
        padding: 10px 15px;
        line-height: $line-height;
        border-radius: $border-radius $border-radius 0px 0px;
    }
    
    .popover-body{
        padding: 10px 15px;
    }
}


/*----- 
    [Validation]
------*/
.was-validated .form-control:valid, 
.form-control.is-valid, 
.was-validated .custom-select:valid, 
.custom-select.is-valid{
    border-color: $success-background;
}
.was-validated .form-control:invalid, 
.form-control.is-invalid, 
.was-validated .custom-select:invalid, 
.custom-select.is-invalid{
    border-color: $danger-background;
}
.invalid-feedback{
    color: $danger-background;
    font-size: $font-size - 2;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, 
.custom-control-input.is-invalid ~ .custom-control-indicator{
    background-color: transparentize($danger-background, 0.75);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description, 
.custom-control-input.is-invalid ~ .custom-control-description{
    color: $danger-background;
}


/*----- 
    [Tables]
------*/
.table{
    margin-bottom: 20px;
    
    .custom-control{
        margin: 0px;
        padding-left: 20px;
        
        .custom-control-label{
            display: block;
            
            &:after,&:before{
                left: -20px;
            }
        }
    }
    
    th{        
        color: $color-heading;
    }
    
    th,td{
        padding: 10px;
        border-top: 1px solid $border-color;
        vertical-align: middle;
    }
    
    thead{
        th{
            //border-bottom: 1px solid $border-color;
            border-bottom: 0px;
            border-top: 0px;
            font-weight: 500;
        }
    } 
    tfoot{
        th{
            border-top: 1px solid $border-color;
            border-bottom: 0px;
        }
    }
    tbody{
        th{
            font-weight: 500;
        }
    }
    
    &.table-dark{
        color: $invert-color;
        background-color: $invert-sidepanel-background;        
        
        th{
            color: $invert-color-heading;
        }
        
        th,td{
            border-color: $invert-sidepanel-border;
        }
        thead{
            th{
                border-color: $invert-sidepanel-border;
            }
        }        
    }
    
    .thead-dark{
        th{
            color: $invert-color;
            background-color: $invert-sidepanel-background; 
            border-color: $invert-sidepanel-border;
        }
    }
    
    .thead-light{
        th{
            background: #fafafa;
        }
    }
    
    &.table-striped{
        tbody {
            tr:nth-of-type(odd){
                background: rgba(0,0,0,0.02);
            }
        }
    } 
    
    &.table-hover{
        tbody{
            tr{
                @if $animation != 0 {
                    @include transition(all $animation ease-in-out);
                }
                &:hover{
                    background: rgba(0,0,0,0.03);
                }
            }
        }
    }
    
    &.table-bordered{
        border-top: 1px solid $border-color;
    }
    
    &.table-indent-rows{
        border-collapse: separate;
        border-spacing: 0 10px;        
        border: 0px;
        
        tbody{
            td,th{
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;

                &:first-child{
                    border-left: 1px solid $border-color;
                }
                &:last-child{
                    border-right: 1px solid $border-color;
                }
            }
        }
    }
}


/*----- 
    [Pagination]
------*/
.pagination{
    border-radius: $border-radius;
    
    .page-item{    
        .page-link{
            padding: 10px;
            line-height: $line-height;
            min-width: 40px;
            text-align: center;
            color: $color;
            border-color: $border-color;
            outline: none;
            background: $base-skin-background;
            @if $animation != 0 {
                @include transition(all $animation ease-in-out);
            }
            
            &:hover{
                background: darken($base-skin-background, 5%);
            }
        }
        &.active{
            .page-link{
                border-color: darken($primary-background,10%);
                background: $primary-background;
                color: $primary-color;
            }
        }
        &.disabled{
            .page-link{
                color: $color-secondary;
            }
        }
        &:first-child{
            .page-link{
                border-radius: $border-radius 0px 0px $border-radius;
            }
        }
        &:last-child{
            .page-link{
                border-radius: 0px $border-radius $border-radius 0px;
            }
        }
    }
    
    &--space{
        .page-item{    
            .page-link{
                margin-right: 5px;
                border-radius: $border-radius !important;
            }
        }
    }
    
    &.pagination-lg{        
        .page-item{
            .page-link{
                padding: 14px 15px;
                min-width: 50px;
                font-size: $font-size;
            }
        }                
    }
    &.pagination-sm{
        .page-item{
            .page-link{
                padding: 4px 10px;
                min-width: 30px;
                font-size: $font-size - 1;
            }
        }
    }
    
}

/*----- 
    [Progress]
------*/
.progress{
    height: $line-height;
    margin: 10px 0px;
    border-radius: $border-radius;
    font-size: $font-size - 2;
    background: rgba(0,0,0,0.05);
    
    .progress-bar{
        background: $primary-background;
        color: $primary-color;    
        
        &.progress-bar-striped{
            background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
            background-size: 1rem 1rem;
        }
    }
}