/*------------------------------------------------------------------

    [Page heading]

    Project:        The Right Way
    Version:        v1.0.0
    Last change:    17/05/18 [Release]
    
    
    Universal head container

    + Page heading / .page-heading
        - Container / .page-heading__container - container for content
        - Breadcrumb / .breadcrumb - bootstrap breadcrumb support
    
-------------------------------------------------------------------*/


/*-----
    [Page heading / .page-heading]
-----*/
.page-heading{
    width: 100%;
    position: relative;
    background: darken($sidepanel-background,1%);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    @if $shadows != 0 {
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.1);
    }
    @include clearfix();
    
    /*-----
        [Page heading container / .page-heading__container]
    -----*/
    .page-heading__container{
        display: block;
        padding: 15px 20px;        
        float: left;
        position: relative;
        
        .icon{
            line-height: 40px;
            height: 40px;
            float: left;
            margin-right: 10px;
            text-align: left;
            
            *[class^="fa-"]{
                font-size: 28px;
            }
            *[class^="li-"]{
                font-size: 30px;
                line-height: 40px;
            }
        }
        
        .title{
            font-size: 15px;
            margin: 3px 0px 0px;            
            padding: 0px;            
            line-height: $line-height;
            color: $color-heading;
            font-weight: 500;  
            white-space: nowrap;
            
            &--sm{
                font-size: 13px;
                font-weight: 500;
            }
            
            > a{
                color: $color-heading;
                
                &.active{
                    text-decoration: underline;
                }
            }
        }
        
        .caption{
            font-weight: 400;
            line-height: $line-height - 5;
            color: $color-secondary;
            white-space: nowrap;
        }
        
        /*-----
            [Page heading container centralized / .page-heading__container--center]
        -----*/
        &--center{
            width: 100%;           
            text-align: center;                        
        }
        
        /*-----
            [Page heading container left position / .page-heading__container--left]
        -----*/
        &--left{
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 2;
        }
        
        /*-----
            [Page heading container right position / .page-heading__container--right]
        -----*/
        &--right{
            position: absolute;
            right: 0px;
            top: 0px;
            z-index: 2;
        }
        
        &.float-right{
            padding-right: 10px;
        }
    }
    
    /*-----
        [Bootstrap breadcrumb support / .breadcrumb]
    -----*/
    .breadcrumb{
        border-top: 1px solid rgba(0,0,0,0.1);
        background: darken($sidepanel-background,2%);
        margin-bottom: 0px;
        padding: 5px 20px 4px;
        width: 100%;
        
        .breadcrumb-item{
            font-size: $font-size - 2;
            
            > a{color: $color-secondary;}            
            &.active{color: $color;}
            
            &:before{
                color: $color-secondary;                
            }
            & + .breadcrumb-item{
                &:before{
                    content: "\f105";
                    font-family: FontAwesome;
                }
            }
        }
    }    
}

/*-----
    [Bootstrap card support]
-----*/
.card{
    .page-heading + .card-container{
        top: 20px;
    }
}


/*-----
    [Support page-heading under page__header]
-----*/
.page{
    > .page-heading{
        background: transparent;
        border: 0px;
        margin-top: 30px;
        box-shadow: none;
        
        .page-heading__container{
            padding: 0px 20px;       
            text-shadow: 0px 1px 2px rgba(0,0,0,0.3);
            
            .icon{
                color: $color-invert;
            }
            
            .title{
                color: $color-invert;
            }
            p.caption{
                color: darken($color-invert,5%);
            }                        
        }
        
        .breadcrumb{
            background: transparent;
            border: 0px;
            padding-bottom: 0px;
            
            .breadcrumb-item{
                > a{color: darken($color-invert,20%);}            
                &.active{color: darken($color-invert,5%);}

                &:before{
                    color: darken($color-invert,20%);
                }
            }
        }
    }    
}